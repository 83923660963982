import { TextField } from '@mui/material'
import React from 'react'
import { Button, Image, Row } from 'react-bootstrap'
import companyLogoDark from '../../assets/images/companyLogoDark.svg'
import './checkmail.scss'
import checkmail from '../../assets/images/checkmail.png'

export default function Checkmail() {
    return (
        <div className='bg-white vh-100 checkmail'>
            <div className=''>
                <Image className='mt-5 ms-3' src={companyLogoDark} alt="" height={"110px"} />
            </div>
            <Row className='text-center justify-content-center content-block'>
                <Row className='justify-content-center checkmail_img p-0'>
                    <img src={checkmail} alt="" height={"180px"} className="p-0" />
                </Row>
                <Row className='justify-content-center local-text'>Check your mail</Row>
                <Row className='justify-content-center Fp_description'>
                    <p>Check your mail. We have sent a password recovery instructions along with a link.</p>
                </Row>
            </Row>
            <Row className='footer-content'>
                <p className='d-inline text-center'>Did not receive the mail? Check your spam filter.</p>
            </Row>
        </div>
    )
}
