import React, { useState } from 'react'
import { Row } from 'react-bootstrap'
import { useNavigate, } from 'react-router'
import DropZoneComponent from '../../components/common/dropzoneComponent/DropzoneComponent'
import Navbar from '../../components/common/navbar/Navbar'
import './update.scss'
import { postMethod, putMethod } from '../../utils/apis/services/apis';
import url from '../../utils/apis/services/urls.json';
import ToastService from "react-material-toast";
import { useParams } from 'react-router'

const toast = ToastService.new({
    place: "topRight",
    duration: 2,
    maxCount: 4
});

export default function Update() {
    const params = useParams();
    const navigate = useNavigate();
    const [dataToPost, setDataToPost] = useState({});
    const getPostData = (data) => {
        setDataToPost(data)
    }
    const postUpdateData = async (value) => {
        if (params.id === "create") {
            let body = {
                "business_id": value.bussinessID,
                "location": value.location,
                "description": value.description,
                "country_id": value.countryID,
                "state_id": value.stateID,
                "cities_id": value.cityID,
                "tag_list": value.addTags,
            }

            if (value.videoID.length !== 0) {
                body.video_ids = value.videoID
            }

            if (value.imageID.length !== 0) {
                body.image_ids = value.imageID
            }

            const result = await postMethod(url.createUpdatePosts, body);

            if (result.status === 200) {
                toast.success(result.data.response)
                navigate("/post:updatelist");
            } else if (result.status === 400) {

                if (result.data.error.business_id && result.data.error.business_id[0]) {
                    toast.error("business Name may not be blank");
                } else if (result.data.error.cities_id && result.data.error.cities_id[0]) {
                    toast.error("Cities Name may not be blank");
                } else if (result.data.error.country_id && result.data.error.country_id[0]) {
                    toast.error("Country Name may not be blank");
                } else if (result.data.error.description && result.data.error.description[0]) {
                    toast.error("Description may not be blank");
                } else if (result.data.error.location && result.data.error.location[0]) {
                    toast.error("Location may not be blank");
                } else if (result.data.error.state_id && result.data.error.state_id[0]) {
                    toast.error("State Name not be blank");
                } else if (result.data.error.tag_list && result.data.error.tag_list[0]) {
                    toast.error("Tags List may not be blank");
                } else if (result.data.error.image_ids && result.data.error.image_ids[0]) {
                    toast.error("Image Field may not be blank");
                }

            } else {
                toast.error(result.request.statusText);
            }
        } else {

            let body = {
                "business_id": value.bussinessID,
                "location": value.location,
                "description": value.description,
                "country_id": value.countryID,
                "state_id": value.stateID,
                "cities_id": value.cityID,
                "tag_list": value.addTags,
                "isActive": true,
                "image_ids": value.imageID,
                "video_ids": value.videoID
            }

            const result = await putMethod(url.putPostUpdate + params.id, body);

            if (result.status === 200) {
                toast.success(result.data.response)
                navigate("/post:updatelist");
            } else if (result.status === 400) {

                if (result.data.error.business_id && result.data.error.business_id[0]) {
                    toast.error("business Name may not be blank");
                } else if (result.data.error.cities_id && result.data.error.cities_id[0]) {
                    toast.error("Cities Name may not be blank");
                } else if (result.data.error.country_id && result.data.error.country_id[0]) {
                    toast.error("Country Name may not be blank");
                } else if (result.data.error.description && result.data.error.description[0]) {
                    toast.error("Description may not be blank");
                } else if (result.data.error.location && result.data.error.location[0]) {
                    toast.error("Location may not be blank");
                } else if (result.data.error.state_id && result.data.error.state_id[0]) {
                    toast.error("State Name not be blank");
                } else if (result.data.error.tag_list && result.data.error.tag_list[0]) {
                    toast.error("Tags List may not be blank");
                } else if (result.data.error.image_ids && result.data.error.image_ids[0]) {
                    toast.error("Image Field may not be blank");
                }
            } else {
                toast.error(result.request.statusText);
            }
        }
    }

    return (
        <div className='update'>
            <Navbar backBtn={true} />
            <div className='content_area'>
                <div className="content_block d-flex justify-content-between">
                    <div className="d-flex">
                        <div className="back_btn pe-4" style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="#000" Name="bi bi-arrow-left-short" viewBox="0 0 16 16" style={{ margin: "0px" }}>
                                <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
                            </svg>
                        </div>
                        <i className="bi bi-plus-circle d-flex align-self-center"></i>
                        <p className='d-inline-block m-0'>Create Update</p>
                    </div>

                    <button className='commom_btn' onClick={() => postUpdateData(dataToPost)}>{params.id === "create" ? "Create" : "Update"}</button>
                </div>
                <Row>
                    <DropZoneComponent notInupdate={false} postingData={(data) => getPostData(data)} method={"post-update"} />
                </Row>
            </div>
        </div>
    )
}
