import React from 'react'
import { Button } from 'react-bootstrap'
import './navbar.scss'
import logout from '../../../assets/images/logout.svg'
import { useNavigate } from 'react-router';
import { postMethod, postMethodwithoutBody } from '../../../utils/apis/services/apis';
import url from '../../../utils/apis/services/urls.json';
import { getToken } from '../../../utils/apis/services/common';
import { confirmAlert } from 'react-confirm-alert';
import Notifications from "../../../assets/images/notifications.png";
import ToastService from "react-material-toast";

const toast = ToastService.new({
  place: "topRight",
  duration: 2,
  maxCount: 4,
});

export default function Navbar(props) {
  const navigate = useNavigate();

  const logout = async () => {
    const response = await postMethodwithoutBody(url.logout)
    if (response.status === 200 || response.status === 201) {
      navigate("/");
      toast.success(response.data.message);
    } else {
      toast.error(response.data.response);
    }

  }
  const deleteConfirm = async () => {
    confirmAlert({
      title: 'Confirm to Logout',
      message: 'Are you sure to Logout?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            logout();
          }
        },
        {
          label: 'No',
          onClick: () => {
          }
        }
      ]
    });
  }

  const handlePushNotifications = () => {
    navigate("/businesses/pushnotifications");
  }

  return (
    <div className='navbar d-flex justify-content-end'>
      <div></div>
      <img className="me-3" style={{ cursor: "pointer" }} onClick={() => handlePushNotifications()} src={Notifications} />
      <Button className='logout_btn' onClick={() => deleteConfirm()}>
        <img src={logout} alt="" />
        Log out
      </Button>
    </div>
  )
}
