import React, { useState } from 'react'
import { Row } from 'react-bootstrap'
import Card from '../../components/common/card/Card'
import Navbar from '../../components/common/navbar/Navbar'
import './dashboard.scss'
import '../../styles/common.scss'
import territories from '../../assets/images/territories.svg'
import countryLogo from '../../assets/images/countryLogo.png'
import AdditionalLogo from '../../assets/images/additionalinfo.svg'
import terms from '../../assets/images/terms.svg'
import privacy from '../../assets/images/privacy.svg'
import setting from '../../assets/images/setting.svg'
import { useNavigate } from 'react-router'

export default function Dashboard() {
  const navigate = useNavigate();
  const cards = [
    {
      id: 1,
      icon: territories,
      heading: "Territories",
      peragraph: "Operational Areas where we are currently providind services.",
      countryLogo: countryLogo,
      optional: "01 Operational Area"
    },
    {
      id: 2,
      icon: privacy,
      heading: "Privacy Policy",
      peragraph: "Your data is safe with us. We don’t share you information with anyone.",
    },
    {
      id: 3,
      icon: terms,
      heading: "Terms of Service",
      peragraph: "Rules users must follow when interacting with other users.",
    },
    {
      id: 4,
      icon: AdditionalLogo,
      heading: "Additional Information",
      peragraph: "Rules users must follow when interacting with other users.",
    },

  ]
  const [count, setCount] = useState(0);
  return (
    <div className='dashboard'>
      <Navbar backBtn={false} />

      <div className='content_area'>
        <div className="content_block">

          <img src={setting} alt="" height={"30px"} />
          <p className='d-inline-block'>Settings</p>
        </div>
        <Row>
          <Card cards={cards} />
        </Row>
      </div>
    </div>
  )
}
