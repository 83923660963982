import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Row, Col, Button } from "react-bootstrap";
import BannerA from "../../assets/images/banner/BannerA.jpg";
import BannerB from "../../assets/images/banner/BannerB.jpg";
import BannerC from "../../assets/images/banner/BannerC.jpg";
import BannerD from "../../assets/images/banner/BannerD.jpg";
import BannerE from "../../assets/images/banner/BannerE.jpg";
import BannerF from "../../assets/images/banner/BannerF.jpg";
import BannerG from "../../assets/images/banner/BannerG.jpg";
import locallogo from "../../assets/images/berb-logo.svg";
import { TextField, Checkbox } from "@mui/material";
import "../../../src/index.scss";
import { useNavigate } from "react-router-dom";
import { postWithoutTokenMethod } from "../../utils/apis/services/apis";
import url from "../../utils/apis/services/urls.json";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useForm, Controller } from "react-hook-form";
import ToastService from "react-material-toast";
import Loader from "../loader/Loader";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import * as Yup from "yup";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const toast = ToastService.new({
  place: "topRight",
  duration: 2,
  maxCount: 4,
});
export default function CreatePassword() {
  const [loading, setLoading] = useState(false);

  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const [passwordType, setPasswordType] = React.useState(true);
  const [userId, setUserId] = useState("");
  const [conformPasswordType, setConformPasswordType] = React.useState(true);

  var currentUrl = window.location;
  var access_token = new URLSearchParams(currentUrl.search).get("token");
  const ValidateToken = async () => {
    let body = {
      token: access_token,
    };
    const result = await postWithoutTokenMethod(url.postValidateToken, body);
    if (result.status === 200) {
      toast.success(result.data.response);
      setUserId(result.data.userId);
    }
  };
  useEffect(() => {
    ValidateToken();
  }, []);

  const postCreatePassword = async (value) => {
    const body = {
      userId: userId,
      password: utf8_to_b64(value.password),
    };
    setLoading(true);
    const response = await postWithoutTokenMethod(url.postNewPassword, body);

    const onErrorClick = () => {
      toast.error(response.data.error);
    };

    if (response.status == 200) {
      setLoading(false);
      toast.success(response.data.response);
      navigate("/");
    } else {
      setLoading(false);
      onErrorClick();
    }
    setLoading(false);
  };
  const navigate = useNavigate();
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
  };

  function utf8_to_b64(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
  }

  const handleVisiblity = () => {
    setPasswordType(!passwordType);
  };

  const handleConfirmVisiblity = () => {
    setConformPasswordType(!conformPasswordType);
  };

  const Schema = Yup.object().shape({
    password: Yup.string().
      required("This field is required")
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must contain at least 8 characters, one uppercase, one number and one special case character"
      )
    ,
    changepassword: Yup.string().when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be the same"
      ),
    }),
  });

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Row className="login h-100vh">
          <Col xs={12} sm={12} md={4} lg={5} xl={5} className="bannerSide">
            <Col xs={12} sm={12} md={4} lg={5} xl={5} className="upper text-center align-self-center">
              <img className="localLogo" src={locallogo} alt="logo" />
            </Col>
            <Slider {...settings} className="text-end">
              <div>
                <img className="banner" src={BannerA} alt="" />
              </div>
              <div>
                <img className="banner" src={BannerB} alt="" />
              </div>
              <div>
                <img className="banner" src={BannerC} alt="" />
              </div>
              <div>
                <img className="banner" src={BannerD} alt="" />
              </div>
              <div>
                <img className="banner" src={BannerE} alt="" />
              </div>
              <div>
                <img className="banner" src={BannerF} alt="" />
              </div>
              <div>
                <img className="banner" src={BannerG} alt="" />
              </div>
            </Slider>
          </Col>
          <Col xs={12} sm={12} md={8} lg={7} xl={7} className="form-side  m-auto">
            <Formik
              initialValues={{
                password: "",
                changepassword: "",
              }}
              validationSchema={Schema}
              onSubmit={(value) => {
                postCreatePassword(value);
              }}
            >
              {({ values, errors, handleSubmit, handleChange, handleBlur }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <h3>Create New Password</h3>
                      <TextField
                        className="password_field"
                        placeholder="Password"
                        type={passwordType ? "password" : "text"}
                        value={values.password}
                        variant="standard"
                        onChange={handleChange}
                        name="password"
                        onBlur={handleBlur}
                        InputProps={{
                          endAdornment: passwordType ? (
                            <VisibilityIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => handleVisiblity()}
                            />
                          ) : (
                            <VisibilityOffIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => handleVisiblity()}
                            />
                          ),
                        }}
                      />

                      <span class="error" style={{ color: "red" }}>
                        {errors.password}
                      </span>

                      <TextField
                        className="password_field"
                        placeholder="Confirm Password"
                        type={conformPasswordType ? "password" : "text"}
                        value={values.changepassword}
                        variant="standard"
                        error={errors.changepassword}
                        onChange={handleChange}
                        name="changepassword"
                        onBlur={handleBlur}
                        InputProps={{
                          endAdornment: conformPasswordType ? (
                            <VisibilityIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => handleConfirmVisiblity()}
                            />
                          ) : (
                            <VisibilityOffIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => handleConfirmVisiblity()}
                            />
                          ),
                        }}
                      />

                      <span class="error" style={{ color: "red" }}>{errors.changepassword}</span>

                      <Button className="continue_button" type="submit">Save</Button>

                    </Row>
                  </form>
                );
              }}
            </Formik>
          </Col>
        </Row>
      )}
    </>
  );
}

