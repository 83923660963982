import axios from "axios";
import { getToken } from "./common";
import hmac_key from '../locale/env.json';
import base_url from '../locale/env.json';

var CryptoJS = require("crypto-js");

const hmacConvert = (method, data) => {
  var string = `${hmac_key.hmacKey}-${method}-${data !== "" ? JSON.stringify(data) : ""}`;
  var hash = CryptoJS.HmacSHA256(string, hmac_key.hmacKey);
  var hashInBase64 = CryptoJS.enc.Base64.stringify(hash);
  return hashInBase64;
};

export const getMethod = async (url) => {
  let hKey = await hmacConvert("GET", "");
  try {
    let data = await axios({
      method: "GET",
      url: base_url.baseUrl + url,
      headers: {
        accept: "application/json",
        Authorization: "token " + getToken(),
        "device-type": "web",
        "Signing-Key": hKey
      },
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const postMethod = async (url, body) => {
  let hKey = await hmacConvert("POST", body);
  try {
    let data = await axios({
      method: "POST",
      url: base_url.baseUrl + url,
      headers: {
        accept: "application/json",
        Authorization: "token " + getToken(),
        "device-type": "web",
        "Signing-Key": hKey
      },

      data: body,
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const postMethodwithoutBody = async (url) => {
  let hKey = await hmacConvert("POST", "");
  try {
    let data = await axios({
      method: "POST",
      url: base_url.baseUrl + url,
      headers: {
        accept: "application/json",
        Authorization: "token " + getToken(),
        "device-type": "web",
        "Signing-Key": hKey
      },
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const postWithoutTokenMethod = async (url, body) => {
  let hKey = await hmacConvert("POST", body);
  try {
    let data = await axios({
      method: "POST",
      url: base_url.baseUrl + url,
      data: body,
      headers: {
        accept: "application/json",
        "device-type": "web",
        "Signing-Key": hKey
      },
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const postUploadMethod = async (url, body) => {
  let hKey = await hmacConvert("POST", body);
  let token = getToken();
  try {
    let data = await axios({
      method: "POST",
      url: base_url.baseUrl + url,
      data: body,
      headers: {
        Authorization: "token " + token,
        "Content-Type": "multipart/form-data",
        "device-type": "web",
        "Signing-Key": hKey
      },
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const putMethod = async (url, body) => {
  let hKey = await hmacConvert("PUT", body);
  try {
    let data = await axios({
      method: "PUT",
      url: base_url.baseUrl + url,
      headers: {
        accept: "application/json",
        Authorization: "token " + getToken(),
        "device-type": "web",
        "Signing-Key": hKey
      },
      data: body,
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const deleteMethod = async (url, id) => {
  let hKey = await hmacConvert("DELETE", "");
  try {
    let data = await axios({
      method: "DELETE",
      url: base_url.baseUrl + url,
      headers: {
        accept: "application/json",
        Authorization: "token " + getToken(),
        "device-type": "web",
        "Signing-Key": hKey
      },
      data: id,
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

export const deleteMethodWithId = async (url, id) => {
  let hKey = await hmacConvert("DELETE", id);
  try {
    let data = await axios({
      method: "DELETE",
      url: base_url.baseUrl + url,
      headers: {
        accept: "application/json",
        Authorization: "token " + getToken(),
        "device-type": "web",
        "Signing-Key": hKey
      },
      data: id,
    });
    return data;
  } catch (error) {
    return error.response;
  }
}
