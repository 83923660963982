import React, { useCallback, useEffect, useState } from 'react';
import { Editor } from "react-draft-wysiwyg";
import {
    EditorState,
    ContentState,
    convertToRaw,
    convertFromHTML
} from "draft-js";
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './textEditor.scss';
import draftToHtml from "draftjs-to-html";
import { useNavigate } from "react-router-dom";
import ToastService from "react-material-toast";
import htmlToDraft from "html-to-draftjs";
import { getMethod } from '../../../utils/apis/services/apis';
import url from "../../../utils/apis/services/urls.json";
import Loader from '../../../screens/loader/Loader';

const toast = ToastService.new({
    place: "topRight",
    duration: 2,
    maxCount: 4,
});

export default function TextEditor({ privacyPolicyList, TermsOfConditionList, getEditorData, TermsOfCondition, PrivacyPolicy }) {

    const navigate = useNavigate();
    const [htmlText, setHtmlText] = useState("");
    const [editorState, setEditorState] = useState("");
    const [privacyPolicyId, setPrivacyPolicyId] = useState("");
    const [loading, setLoading] = useState(false)

    const [contentState, setContentState] = useState("")
    const handleGetData = async () => {
        if (PrivacyPolicy === true) {
            setLoading(true)
            let result = await getMethod(url.getPrivacyPolicyData);

            if (result.status === 401) {
                toast.error(result.data.detail);
                navigate("/");
            }
            setPrivacyPolicyId(result.data[0].id)
            setLoading(false)
            const blocksFromHtml = htmlToDraft(result.data[0].policy_text);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(
                contentBlocks,
                entityMap
            );
            const raw = convertToRaw(contentState)
            setContentState(raw)
        } else {
            setLoading(true)
            let result = await getMethod(url.getTermOfUseData);

            if (result.status === 401) {
                toast.error(result.data.detail);
                navigate("/");
            } else {
                setPrivacyPolicyId(result.data[0].id)
                setLoading(false)
                const blocksFromHtml = htmlToDraft(result.data[0].term_text);
                const { contentBlocks, entityMap } = blocksFromHtml;
                const contentState = ContentState.createFromBlockArray(
                    contentBlocks,
                    entityMap
                );
                const raw = convertToRaw(contentState)
                setContentState(raw)
            }
        }
    }

    const onEditorStateChange = (e) => {
        if (PrivacyPolicy === true) {
            setEditorState(e);
            let val = draftToHtml(convertToRaw(e.getCurrentContent()));
            setHtmlText(val);
        } else {
            setEditorState(e);
            let val = draftToHtml(convertToRaw(e.getCurrentContent()));
            setHtmlText(val);
        }
    };
    useEffect(() => {
        handleGetData()
    }, [])

    useEffect(() => {
        getEditorData(htmlText, privacyPolicyId)
        // handleGetData()
    }, [htmlText, privacyPolicyList, TermsOfConditionList])

    return (
        <div className='textEditor'>
            <div className="editor_container">
                {PrivacyPolicy && (contentState !== "" && !loading ?
                    <Editor
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        defaultContentState={contentState !== "" && contentState}
                        onEditorStateChange={onEditorStateChange}
                    />
                    :
                    <div style={{ background: "white", height: "80vh", display: "flex", justifyContent: "center" }}>
                        <Loader loading ={loading} />
                    </div>

                )}
                {TermsOfCondition && (contentState !== "" && !loading ?
                    <Editor
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        defaultContentState={contentState !== "" && contentState}
                        onEditorStateChange={onEditorStateChange}
                    />
                    :
                    <div style={{ background: "white", height: "80vh", display: "flex", justifyContent: "center" }}>
                        <Loader loading ={loading} />
                    </div>
                )}
            </div>
        </div>
    )
}
