import { TextField } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Button, Image, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import companyLogoDark from "../../assets/images/companyLogoDark.svg";
import berbLogoDark from "../../assets/images/berb-logo-black.svg";
import "./forgetPassword.scss";
import ToastService from "react-material-toast";
import { postWithoutTokenMethod } from "../../utils/apis/services/apis";
import url from "../../utils/apis/services/urls.json";
import Loader from "../loader/Loader";

const toast = ToastService.new({
  place: "topRight",
  duration: 2,
  maxCount: 3,
});

export default function ForgetPassword() {
  const navigate = useNavigate();
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const [validEmail, setValidEmail] = useState(false);
  const [errorText, setErrorText] = useState();
  const [errorPassword, setErrorPassword] = useState();
  const [phone, setPhone] = useState();
  const { register, handleSubmit, errors } = useForm();
  const [emailAddress, setEmailAddress] = useState("");
  const [loading, setLoading] = useState(false);

  const onChange = (event) => {
    setEmailAddress(event.target.value);
    if (event.target.value.match(emailRegex)) {
      setErrorText("");
      setPhone(event.target.value);
      setValidEmail(true);
    } else {
      setErrorText("Email is invalid ");
      setValidEmail(false);
    }
  };

  const onSubmit = async (data) => {
    if (validEmail) {
      let body = {
        email: emailAddress,
      };
      setLoading(true);

      const result = await postWithoutTokenMethod(url.postForgetPassword, body);

      if (result.status === 200) {
        toast.success(result.data.response);
        navigate("./checkmail");
        setLoading(false);
      }
    } else {
      onErrorClick();
    }
  };

  const onErrorClick = () => {
    const id = toast.error("please fill all the field correctly");
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="bg-white vh-100 forgetPassword">
          <div className="">
            <Image className="mt-5 ms-5" src={berbLogoDark} alt="Berb" width="180px" />
          </div>
          <Row className="text-center justify-content-center content-block">
            <Row className="justify-content-center local-text">Forget Password</Row>
            <Row className="justify-content-center Fp_description">
              <p>Enter email associated with your account. We will send an email with instructions to reset your password.</p>
            </Row>
            <Formik>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row className="justify-content-center input_field">
                  <TextField
                    required
                    name="email"
                    id="email"
                    placeholder="Email Id"
                    variant="standard"
                    className="email_field"
                    helperText={errorText}
                    error={errorText}
                    onChange={onChange}
                  />
                </Row>
                <Row className="justify-content-center">
                  <Button className="continue_button" type="submit">Send Instructions</Button>
                </Row>
              </Form>
            </Formik>
          </Row>
        </div>
      )}
    </>
  );
}
