import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import ToastService from "react-material-toast";
import Navbar from '../../components/common/navbar/Navbar'
import { useNavigate } from 'react-router';
import AdditionalLogo from '../../assets/images/additionalinfo.svg'
import { postMethod, getMethod } from "../../utils/apis/services/apis";
import urls from "../../utils/apis/services/urls.json";
import "./AdditionalInformation.scss";

const toast = ToastService.new({
	place: "topRight",
	duration: 2,
	maxCount: 4,
});

const checkAdditionalInformation = data => {
	const emptyKeys = [
		'days'
	];

	const emptyKeysErrors = [
		'Number of Days',
	];

	for (let i = 0; i < emptyKeys.length; i++) {
		if (!data[emptyKeys[i]]) {
			return `Please fill in ${emptyKeysErrors[i]}`;
		}
	}

	return '';
};

export default function AdditionalInformation() {
	const navigate = useNavigate();

	const [values, setValues] = useState({
		days: "",
	});

	useEffect(() => {
		getAdditionalInformationData();
	}, []);

	const getAdditionalInformationData = async () => {
		await getMethod(urls.getAdditionalInformation).then((response) => {
			if (response.status === 200) {
				setValues({
					...values,
					["days"]: response.data.days,
				});
			}
			else if (response.status === 401) {
				toast.error(response.data.detail);
				navigate("/");
			}
		}).catch((error) => {
			console.log(error);
		});
	};

	const handleInputChanged = (e) => {
		setValues((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};

	const handlePostData = async () => {
		const error = checkAdditionalInformation(values);

		if (error) {
			toast.error(error);
		} else {
			await postMethod(urls.postAdditionalInformation, values).then(async (response) => {
				if (response.status === 200) {
					toast.success(response.data.Success);
				} else {
					toast.error(response.data.error);
				}
			}).catch((error) => {
				console.log("error", error);
			});
		}
	}

	return (
		<div className='additionalInformation'>
			<Navbar backBtn={true} />
			<div className='content_area'>
				<div className="content_block d-flex justify-content-between" style={{ display: "flex", alignItems: "center" }}>
					<div className='d-flex' style={{ display: "flex", alignItems: "center" }}>
						<div className="back_btn pe-4" style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
							<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="#000" Name="bi bi-arrow-left-short" viewBox="0 0 16 16" style={{ margin: "0px" }}>
								<path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
							</svg>
						</div>
						<div className="" style={{ display: "flex", alignItems: "center" }}>
							<img src={AdditionalLogo} alt="" height={"30px"} />
							<p className='m-0' style={{ display: "flex", alignItems: "center" }}>Additional Information</p>
						</div>
					</div>
					<button className='commom_btn' data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => handlePostData()}>Save</button>
				</div>

				<Row>
					<div className="bg-white content-body">
						<Row>
							<Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} className="pe-5 ps-4">
								<p className="dropzone_heading mb-3">How many people are looking at the restaurant?</p>
								<Row className="input_area">
									<input type="number" placeholder="Number of Days" className="form-control" name="days" defaultValue={values.days} onChange={(e) => handleInputChanged(e)}/>
								</Row>
							</Col>
						</Row>
					</div>
				</Row>
			</div>
		</div>
	);
}