import React from "react";
import {
  BrowserRouter as Routers,
  Navigate,
  Route,
  useLocation,
  Routes,
} from "react-router-dom";
import Login from "../screens/Login/Login";
import Dashboard from "../screens/dashboard/Dashboard";
import Territories from "../screens/territories/Territories";
import PrivacyPolicy from "../screens/privacyPolicy/PrivacyPolicy";
import Post from "../screens/post/Post";
import TermOfService from "../screens/termOfService/TermOfService";
import Update from "../screens/update/Update";
import Event from "../screens/event/Event";
import Special from "../screens/special/Special";
import Meetup from "../screens/meetup/Meetup";
import ForgetPassword from "../screens/forgetPassword/ForgetPassword";
import Checkmail from "../screens/checkmail/Checkmail";
import Businesses from "../screens/businesses/Businesses";
import CreateBusiness from "../screens/createBusiness/CreateBusiness";
import Campaigns from "../screens/campaigns/Campaigns";
import Campaign from "../screens/campaigns/Campaign";
import CreatePassword from "../screens/forgetPassword/CreatePassword";
import { getToken } from '../utils/apis/services/common';
import OtpVerification from '../screens/OtpVerifaction/OtpVerification';
import Sidebar from '../components/common/sidebar/Sidebar';
import ClaimedBusiness from '../screens/claimedBusiness/ClaimedBusiness';
import UpdateClaimedBusiness from '../screens/updateClaimedBusiness/UpdateClaimedBusiness';
import AdditionalInformation from "../screens/additionalInformation/AdditionalInformation";
import PushNotification from "../screens/businesses/PushNotification";

export default function Rout() {
  const PrivateRoute = (props) => {
    const { children } = props;
    const isLoggedIn = getToken();
    const location = useLocation();

    return isLoggedIn ? (
      <>{children}</>
    ) : (
      <Navigate
        replace={true}
        to="/"
        state={{ from: `${location.pathname}${location.search}` }}
      />
    );
  };

  return (
    <>
      <Routers>
        <Routes>
          <Route path="/createpassword" element={<CreatePassword />} />
          <Route
            path="/Settings"
            element={
              <PrivateRoute>
                <div className="Sidebar_control">
                  <Sidebar />
                </div>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/businesses"
            element={
              <PrivateRoute>
                <div className="Sidebar_control">
                  <Sidebar />
                </div>
                <Businesses />
              </PrivateRoute>
            }
          />
          <Route
            path="/claimedBusiness/"
            element={
              <PrivateRoute>
                <div className="Sidebar_control">
                  <Sidebar />
                </div>
                <ClaimedBusiness />
              </PrivateRoute>
            }
          />
          <Route
            path="/claimedBusiness/updateClaimedBusiness/:id"
            element={
              <PrivateRoute>
                <div className="Sidebar_control">
                  <Sidebar />
                </div>
                <UpdateClaimedBusiness />
              </PrivateRoute>
            }
          />
          <Route
            path="/businesses/createBusiness/:id"
            element={
              <PrivateRoute>
                <div className="sidebar_business">
                  <Sidebar />
                </div>
                <CreateBusiness />
              </PrivateRoute>
            }
          />
          <Route
            path="/businesses/pushnotifications"
            element={
              <PrivateRoute>
                 <div className="sidebar_business">
                  <Sidebar />
                </div>
                <PushNotification />
              </PrivateRoute>
            }
          />
          <Route
            path="/Settings/territories"
            element={
              <PrivateRoute>
                <div className="Sidebar_control">
                  <Sidebar />
                </div>
                <Territories />
              </PrivateRoute>
            }
          />
          <Route
            path="/Settings/privacyPolicy"
            element={
              <PrivateRoute>
                <div className="Sidebar_control">
                  <Sidebar />
                </div>
                <PrivacyPolicy />
              </PrivateRoute>
            }
          />
          <Route
            path="/Settings/termOfService"
            element={
              <PrivateRoute>
                <div className="Sidebar_control">
                  <Sidebar />
                </div>
                <TermOfService />
              </PrivateRoute>
            }
          />
          <Route
            path="/Settings/additionalInformation"
            element={
              <PrivateRoute>
                <div className="Sidebar_control">
                  <Sidebar />
                </div>
                <AdditionalInformation />
              </PrivateRoute>
            }
          />
          <Route
            path="/post:id"
            element={
              <PrivateRoute>
                <div className="Sidebar_control">
                  <Sidebar />
                </div>
                <Post />
              </PrivateRoute>
            }
          />
          <Route
            path="/post:id/:post-update/:id"
            element={
              <PrivateRoute>
                <div className="Sidebar_control">
                  <Sidebar />
                </div>
                <Update />
              </PrivateRoute>
            }
          />
          <Route
            path="/post:id/:post-event/:id"
            element={
              <PrivateRoute>
                <div className="Sidebar_control">
                  <Sidebar />
                </div>
                <Event />
              </PrivateRoute>
            }
          />
          <Route
            path="/post:id/:post-special/:id"
            element={
              <PrivateRoute>
                <div className="Sidebar_control">
                  <Sidebar />
                </div>
                <Special />
              </PrivateRoute>
            }
          />
          <Route
            path="/post/meetup"
            element={
              <PrivateRoute>
                <div className="Sidebar_control">
                  <Sidebar />
                </div>
                <Meetup />
              </PrivateRoute>
            }
          />
          <Route path="/forgetpassword" element={<ForgetPassword />} />
          <Route path="forgetpassword/checkmail" element={<Checkmail />} />
          <Route
            path="/campaigns"
            element={
              <PrivateRoute>
                <div className="Sidebar_control">
                  <Sidebar />
                </div>
                <Campaigns />
              </PrivateRoute>
            }
          />
          <Route
            path="/campaign/:type/:id"
            element={
              <PrivateRoute>
                <div className="Sidebar_control">
                  <Sidebar />
                </div>
                <Campaign />
              </PrivateRoute>
            }
          />
          <Route
            path="/Otp_Verification"
            element={
              <OtpVerification />
            }
          />
          <Route path="/" element={<Login />} />
        </Routes>
      </Routers>
    </>
  );
}
