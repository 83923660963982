import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import Navbar from "../../components/common/navbar/Navbar";
import "./claimedBusiness.scss";
import { Row, Col } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import url from "../../utils/apis/services/urls.json";
import { getMethod } from "../../utils/apis/services/apis";
import { format } from "date-fns";
import defaultImage from "../../assets/images/defaultImage.png";
import moment from "moment";
import Pagination from "react-js-pagination";
import Stack from "@mui/material/Stack";
import Loader from "../../screens/loader/Loader";
import ToastService from "react-material-toast";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const toast = ToastService.new({
  place: "topRight",
  duration: 2,
  maxCount: 4,
});

function ClaimedBusiness() {
  const navigate = useNavigate();
  const [value, setValue] = useState(1);

  const tab = ["All", "In Review", "Approved", "OnHold"];
  const [claimBusiness, setClaimeBusiness] = useState([]);
  const [currentTab, setCurrentTab] = useState(
    localStorage.getItem("activeTab")
      ? localStorage.getItem("activeTab")
      : "all"
  );
  const [count, setCount] = useState(1);
  const [initialPageNO, setInitialPageNO] = useState(false);
  const [tabSelect, setTabSelect] = useState(
    localStorage.getItem("activeTab") === "in_review"
      ? 1
      : localStorage.getItem("activeTab") === "approved"
        ? 2
        : localStorage.getItem("activeTab") === "onHold"
          ? 3
          : 0
  );
  const [loading, setLoading] = useState(false);

  var [activePage, setPage] = useState(
    parseInt(
      localStorage.getItem("PageNo") ? localStorage.getItem("PageNo") : 1
    )
  );

  useEffect(() => {
    claimedBusinessList();
  }, [currentTab]);

  const claimedBusinessList = async () => {
    setLoading(true);
    const result = await getMethod(
      url.getClaimedBusiness + `${currentTab}` + "&page=" + activePage
    );
    if (result.status === 401) {
      toast.error(result.data.detail);
      navigate("/");
    }
    setClaimeBusiness(result.data && result.data.results);
    setCount(result.data && result.data.count);
    localStorage.setItem("activeTab", currentTab);
    setLoading(false);
  };

  const handleClick = async (item) => {
    navigate(`./updateClaimedBusiness/${item}`);
  };

  const counts = Math.ceil(count / 10);

  const handlePageChange = async (event) => {
    setInitialPageNO(true);
    setLoading(true);
    localStorage.setItem("PageNo", event);
    setPage(event);
    setLoading(false);
  };

  const handleChange = (val) => {
    setTabSelect(val);
    if (val == 0) {
      setCurrentTab("all");
    } else if (val == 1) {
      setCurrentTab("in_review");
    } else if (val == 2) {
      setCurrentTab("approved");
    } else {
      setCurrentTab("onHold");
    }
  };

  return (
    <>
      <div className="claimed-business">
        <Navbar />
        <div className="content_area">
          <div className="content_block d-flex justify-content-between">
            <p className="d-inline-block m-0">Claimed Business</p>
          </div>
          <div style={{ marginTop: "60px", paddingLeft: "16.85px", border: "1 px solid gray" }}>
            <Tabs
              index={tabSelect}
              variant="unstyled"
              onChange={(e) => handleChange(e)}
            >
              <TabList>
                {tab.map((k, i) => (
                  <Tab
                    _selected={{ color: "white", background: "#19C1D6" }}
                    className="tabList"
                    key={k}
                  >
                    {k}
                  </Tab>
                ))}
              </TabList>
              <TabPanels>
                <TabPanel></TabPanel>
              </TabPanels>
            </Tabs>
          </div>
          <Row style={{ marginTop: "80px" }}>
            <>
              <Row className="post_header">
                <Col xs={3} sm={3} md={3} lg={3} xl={5} xxl={5}>
                  <Row>
                    <Col className="align-self-center" xs={3} sm={3} md={2} lg={2} xl={2} xxl={1}></Col>
                    <Col className="align-self-center p-0 text-start" xs={3} sm={3} md={9} lg={9} xl={9} xxl={9}> Name of User</Col>
                  </Row>
                </Col>
                <Col>Email Id</Col>
                <Col className="align-self-center text-center" xs={3} sm={3} md={3} lg={3} xl={2} businessList xxl={2}>Phone Number</Col>
                <Col xs={3} sm={3} md={3} lg={3} xl={2} xxl={2} className="text-end align-self-center">
                  <Row>
                    <Col className="align-self-center text-center" xs={3} sm={3} md={4} lg={4} xl={12} xxl={12} >Date of Approval</Col>
                  </Row>
                </Col>
              </Row>

              {loading ? (
                initialPageNO ?
                  <Loader />
                  :
                  <Loader />
              )
                : claimBusiness.length > 0 ? (
                  claimBusiness.map((item, index) => {
                    return (
                      <Row className={item.isApproved ? "post_card1" : item.onHold ? "post_card2" : "post_card3"}>
                        <Col>
                          <Row>
                            <Col className="align-self-center" xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} style={{ cursor: "pointer" }} isApproved onClick={() => handleClick(item.id)}>
                              <img src={item.user[0].profile_image !== null && item.user[0].profile_image.filePath ? item.user[0].profile_image.filePath : defaultImage} style={{ width: "60px", height: "60px", borderRadius: "50%", }}
                              />
                            </Col>
                            <Col className="align-self-start p-1 pt-2 text-start" xs={3} sm={3} md={9} lg={9} xl={9} xxl={9} style={{ cursor: "pointer" }} onClick={() => handleClick(item.id)} >
                              <p style={{ marginTop: "10px" }}>
                                {item.user[0].first_name}
                                {item.user[0].last_name}
                              </p>
                              <p>{item.business[0].businessName}</p>
                            </Col>
                          </Row>
                        </Col>
                        <Col className="align-self-center text-center" xs={3} sm={3} md={3} lg={3} xl={3} xxl={5} style={{ cursor: "pointer" }} onClick={() => handleClick(item.id)}><p style={{ marginTop: "10px" }}>{item.user[0].email}</p>
                        </Col>
                        <Col className="align-self-center text-center" xs={3} sm={3} md={3} lg={3} xl={2} xxl={2} style={{ cursor: "pointer" }} onClick={() => handleClick(item.id)} >
                          <p style={{ paddingTop: "10px", paddingLeft: "40px", marginTop: "10px" }}>
                            {item.user[0].country_code}
                            {item.user[0].phone}
                          </p>
                        </Col>
                        <Col className="align-self-center text-center" xs={3} sm={3} md={3} lg={3} xl={2} xxl={2} style={{ cursor: "pointer" }} onClick={() => handleClick(item.id)}>
                          <p style={{ paddingTop: "10px", paddingLeft: "40px", marginTop: "10px" }}>{moment(item.created_at).format("DD/MM/YYYY")}</p>
                        </Col>
                      </Row>
                    );
                  })
                ) : (
                  <Row className="post_card" style={{ padding: "40px" }}>
                    <Col>
                      <Row>
                        <Col className="align-self-center" xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}></Col>
                        <Col className="align-self-start p-1 pt-2 text-start" xs={3} sm={3} md={9} lg={9} xl={9} xxl={9}>
                          <p style={{ marginTop: "10px" }}></p>
                          <p></p>
                        </Col>
                      </Row>
                    </Col>
                    <Col className="align-self-center text-center" xs={3} sm={3} md={3} lg={3} xl={3} xxl={5}>
                      <p style={{ marginTop: "10px" }}>No Data Found</p>
                    </Col>
                    <Col className="align-self-center text-center" xs={3} sm={3} md={3} lg={3} xl={2} xxl={2} >
                      <p style={{ paddingTop: "10px", paddingLeft: "40px", marginTop: "10px" }} ></p>
                    </Col>
                    <Col className="align-self-center text-center" xs={3} sm={3} md={3} lg={3} xl={2} xxl={2} >
                      <p style={{ paddingTop: "10px", paddingLeft: "40px", marginTop: "10px" }}></p>
                    </Col>
                  </Row>
                )}
            </>
          </Row>

          <div className="paginations">
            {count > 20 ? (
              <Stack spacing={2}>
                <Pagination
                  firstPageText={
                    <i class="fa fa-angle-double-left" aria-hidden="true"></i>
                  }
                  prevPageText={
                    <i class="fa fa-angle-left" aria-hidden="true"></i>
                  }
                  lastPageText={
                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                  }
                  nextPageText={
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                  }
                  itemsCountPerPage={20}
                  totalItemsCount={count}
                  activePage={activePage}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  activeClass="active"
                  activeLinkClass="active"
                />
              </Stack>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ClaimedBusiness;
