import React from "react";
import { Navigation } from "react-minimal-side-navigation";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import { Icon } from "@mui/material";
import "./sidebar.scss";
import companyLogo from "../../../assets/images/berb-logo-black.svg";
import editpen from "../../../assets/images/editpen.svg";
import demodp from "../../../assets/images/demodp.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useCallback, useState } from "react";
import skyupdate from "../../../assets/images/iconSidebar/skyupdate.png";
import fadeupdate from "../../../assets/images/iconSidebar/fadeupdate.png";
import skyspecial from "../../../assets/images/iconSidebar/skyspecial.png";
import fadespecial from "../../../assets/images/iconSidebar/fadespecial.png";
import skyevent from "../../../assets/images/iconSidebar/skyevent.png";
import fadeevent from "../../../assets/images/iconSidebar/fadeevent.png";
import url from "../../../utils/apis/services/urls.json";
import Dropzone, { useDropzone } from "react-dropzone";
import { postMethod, putMethod, getMethod } from "../../../utils/apis/services/apis";
import ToastService from "react-material-toast";
import Businesses from '../../../assets/images/Businesses.png'
import defaultImage from "../../../assets/images/defaultImage.png";

const toast = ToastService.new({
  place: "topRight",
  duration: 2,
  maxCount: 4,
});

export default function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [businessImages, setBusinessImages] = useState([]);
  const [businessImagesData, setBusinessImagesData] = useState("");
  const [value, setValue] = useState("")
  const [profileNames, setProfileNames] = useState("")


  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      businessImages.push(acceptedFiles[0]);
      setBusinessImages(businessImages);
      imageUpload(acceptedFiles[0]);
    }
  }, []);

  const onDropRejected = useCallback((Files) => { }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    onDropRejected,
    accept: {
      "image/jpeg": [".jpeg", ".png"],
    },
  });

  useEffect(() => {
    getProfile()
  }, [])

  const getProfile = async () => {
    const result = await getMethod(url.getProfile);
    if (result.status === 401) {
      toast.error(result.data.detail);
      navigate("/");
    }
    setBusinessImagesData(result.data[0].profile_image && result.data[0].profile_image.filePath)
    setValue(result.data[0].first_name === "" ? "Name" : result.data[0].first_name);
    setProfileNames(result.data[0].first_name);
  }

  const imageUpload = async (id) => {
    const formData = new FormData();
    formData.append("filePath", id);
    formData.append("fileType", "I");
    const result = await postMethod(url.imageUpload, formData);
    setBusinessImagesData(result.data.filePath);
    ProfileUpdate(result.data[0].id);
  };
  const ProfileUpdate = async (id) => {
    let body = {
      profile_image_id: id,
    };
    const result = await putMethod(url.updateProfile, body);
    getProfile()
  };

  const handleChange = (e) => {
    setProfileNames(e.target.value);
    setValue(e.target.value);
  }

  //heading
  const profileName = async (e) => {
    if (profileNames && profileNames.length > 0) {
      let body = {
        first_name: e.target.value,
      };
      const result = await putMethod(url.updateProfile, body);
      if (result.status === 200) {
        toast.success(result.data.response)
      } else {
        toast.error(result.data.error.first_name)
      }
    } else {
      toast.error("please type something...")
    }
    setProfileNames("")
  }

  useEffect(() => {
    localStorage.removeItem("PageNo");
  }, []);

  return (
    <>
      <div className="text-center d-flex align-item-center flex-column">
        <div className="logo_control">
          <img src={companyLogo} alt="Berb" width="180" />
        </div>
        <div className="ppCircle align-self-center">

          <img style={{ width: "127px", height: "127px" }} src={businessImagesData ? businessImagesData : defaultImage} alt="" />

          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragAccept && <p>All files will be accepted</p>}
            {isDragReject && <p>Some files will be rejected</p>}
            {!isDragActive && (
              <div className="editBtn d-flex justify-content-center" style={{ cursor: "pointer" }}>
                <img src={editpen} alt="edit" className="align-self-center" />
              </div>
            )}
          </div>
        </div>
        <div className="admin_name ">
          <input type="text" value={value} onChange={(e) => handleChange(e)} onBlur={(e) => profileName(e)} style={{ width: "250px", border: "none", textAlign: "center" }} />
        </div>
      </div>

      <Navigation
        activeItemId={location.pathname}
        onSelect={({ itemId }) => {
          localStorage.removeItem("PageNo");
          navigate(itemId);

        }}
        items={[
          {
            title: "Businesses",
            itemId: "/businesses",
            // Optional
            elemBefore: () => (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                fill="currentColor"
                class="bi bi-currency-exchange"
                viewBox="0 0 16 16"
              >
                <path d="M0 5a5.002 5.002 0 0 0 4.027 4.905 6.46 6.46 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05c0-.046 0-.093.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.46 3.46 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98c-.003.046-.003.097-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5zm16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0zm-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787H8.25zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674l.077.018z" />
              </svg>
            ),
          },
          {
            title: "Claimed Business",
            itemId: "/claimedBusiness",
            // Optional
            elemBefore: () => (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                fill="currentColor"
                class="bi bi-currency-exchange"
                viewBox="0 0 16 16"
              >
                <path d="M2 21V3H12V7H22V21H2ZM4 19H6V17H4V19ZM4 15H6V13H4V15ZM4 11H6V9H4V11ZM4 7H6V5H4V7ZM8 19H10V17H8V19ZM8 15H10V13H8V15ZM8 11H10V9H8V11ZM8 7H10V5H8V7ZM12 19H20V9H12V11H14V13H12V15H14V17H12V19ZM16 13V11H18V13H16ZM16 17V15H18V17H16Z" />
              </svg>
            ),
          },
          {
            title: "Campaigns",
            itemId: "/campaigns",
            // Optional
            elemBefore: () => (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-clock"
                viewBox="0 0 16 16"
              >
                <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
              </svg>
            ),
          },
          {
            title: "Post",
            itemId: "/post:create",
            elemBefore: () => (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-plus-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
              </svg>
            ),
            subNav: [
              {
                title: "Update",
                itemId: "/post:updatelist",
                elemBefore: () =>
                  location && location.pathname == "/post:updatelist" ? (
                    <img src={skyupdate}></img>

                  ) : (
                    <img src={fadeupdate}></img>
                  ),
              },
              {
                title: "Event",
                itemId: "/post:eventlist",
                elemBefore: () =>
                  location && location.pathname == "/post:eventlist" ? (
                    <img src={skyevent}></img>
                  ) : (
                    <img src={fadeevent}></img>
                  ),
              },
              {
                title: "Special",
                itemId: "/post:speciallist",
                elemBefore: () =>
                  location && location.pathname == "/post:speciallist" ? (
                    <img src={skyspecial}></img>
                  ) : (
                    <img src={fadespecial}></img>
                  ),
              },
            ],
          },
          {
            title: "Settings",
            itemId: "/settings",
            // Optional
            elemBefore: () => (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-gear"
                viewBox="0 0 16 16"
              >
                <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
              </svg>
            ),
          },
        ]}
      />
    </>
  );
}
