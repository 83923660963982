import React, { useCallback, useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useLocation, useNavigate, useParams } from "react-router";
import FormControl from "@mui/material/FormControl";
import moment from "moment";
import { InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker, MobileTimePicker, TimePicker } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Navbar from "../../components/common/navbar/Navbar";
import { postMethod, getMethod, putMethod, } from "../../utils/apis/services/apis";
import url from "../../utils/apis/services/urls.json";
import ToastService from "react-material-toast";
import LinkedColor from "../../assets/images/campaign/skyLinked.png";
import AssociationColor from "../../assets/images/campaign/skyAssociation.png";
import uploadicon from "../../assets/images/uploadicon.svg";
import "./Campaign.scss";
import { ListSubheader, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { padding } from "@mui/system";
import { DebounceInput } from "react-debounce-input";
import { CleaningServices } from "@mui/icons-material";

const toast = ToastService.new({
  place: "topRight",
  duration: 2,
  maxCount: 4,
});

export default function Campaign() {
  const itemsPerPage = 20;
  const navigate = useNavigate();
  const params = useParams();
  const [countryList, setCountryList] = useState([]);
  const [countryId, setCountryId] = useState();
  const [stateList, setStateList] = useState([]);
  const [stateId, setStateId] = useState();
  const [cityList, setCityList] = useState([]);
  const [selectItems, setSelectItems] = useState([]);
  const [startDateValue, setStartDateValue] = React.useState(new Date(""));
  const [endDateValue, setEndDateValue] = React.useState(new Date(""));
  const [campaignImages, setCampaignImages] = useState("");
  const [campaignImagesData, setCampaignImagesData] = useState("");
  const [campaignImagesIds, setCampaignImagesIds] = useState("");
  const [weekDays, setWeekDays] = useState([]);
  const [businessList, setBusinessList] = useState([]);
  const [businessIdList, setBusinessIdList] = useState([]);
  const [businessListAll, setBusinessListAll] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [businnesMasterId, setBusinnesMasterId] = useState([]);
  const [stateDataList, setStateDataList] = useState([]);
  const [cityDataList, setCityDataList] = useState([]);
  const [weekdayOrder, setWeekdayOrder] = useState([{}]);
  const [count, setCount] = useState(0)
  const [values, setValues] = useState({
    name: "",
    country_id: "",
    state_id: "",
    cities_id: "",
    english_image_url: "",
    promotion_type:
      params.type === "linkedbased"
        ? "link-based-promotion"
        : "association-based-promotion",
    english_description: "",
    start_date: "",
    end_date: "",
    weekday: [{}],
    english_link: "",
    is_active: true,
  });
  function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }
  const handleSearchRemove = () => {
    setBusinessList([]);
    setSearchText("");
  };

  const searchBusinessList = async (value) => {
    let tempList = [];

    if (value !== "" && value !== undefined) {
      const result = await getMethod(url.searchBusinessList + value.toLowerCase());
      setValues({
        ...values,
        location: value,
      });
      setBusinessList(result?.data?.results);
    } else {
      setBusinessList([]);
      handleSearchRemove();
    }
  };

  const processChange = debounce((value) => searchBusinessList(value));
  useEffect(() => {
    getCountryListData();
    getWeekListData();

    if (params.type !== "linkedbased") {
      getBusinessListData();
    }

    if (params.id !== "create") {
      getCampaignData(params.id);
    }
  }, []);

  useEffect(() => {
    getCountryListData();
  }, [params.id !== "create", values.state_id]);

  const getCountryListData = async () => {
    const result = await getMethod(url.getCountryList);
    if (result.status === 401) {
      toast.error(result.data.detail);
      navigate("/");
    }
    setCountryList(result.data);
    if (params.id !== "create") {
      getStateListData(result.data[0].id);
    }
  };

  const getStateListData = async (country_id) => {
    const result = await getMethod(
      url.getCountryList + "?country_id=" + country_id
    );

    setStateList(result.data[0].data.state);
    setStateDataList(result.data[0].data.state);

    if (params.id !== "create" && values.state_id) {
      getCityListData(country_id, values.state_id);
    }
  };

  const getCityListData = async (country_id, state_id) => {
    const result = await getMethod(
      url.getCountryList + "?country_id=" + country_id + "&state_id=" + state_id
    );
    setCityList(result.data[0].data.cities);
    setCityDataList(result.data[0].data.cities);
  };

  const getWeekListData = async () => {
    const result = await getMethod(url.getWeeksData);

    if (result.status === 401) {
      toast.error(result.data.detail);
      navigate("/");
    }

    const weekdata = result.data.sort(function (obj1, obj2) {
      // Ascending: first id less than the previous
      return obj1.id - obj2.id;
    });

    if (params.id === "create") {
      setSelectItems(weekdata.map((item) => item.id));
      let items = weekdata.map((item) => item.id);
      items.map((k, i) => (weekdayOrder[0][i + 1] = k));

      setValues({
        ...values,
        ["weekday"]: weekdayOrder,
      });
    }

    setWeekDays(weekdata);
  };

  const getBusinessListData = async () => {
    const result = await getMethod(url.getBusinessData);
  };

  const getCampaignData = async (id) => {
    const result = await getMethod(url.getCampaign + id + "/");

    setStartDateValue(result.data.start_date);
    setEndDateValue(result.data.end_date);

    setValues((prevState) => ({
      ...prevState,
      ["name"]: result.data.name && result.data.name,
      ["country_id"]: result.data.country && result.data.country,
      ["state_id"]: result.data.state && result.data.state,
      ["cities_id"]: result.data.cities && result.data.cities,
      ["english_image_url"]:
        result.data.english_image_url.id !== null
          ? result.data.english_image_url.id
          : "",
      ["english_description"]:
        result.data.english_description && result.data.english_description,
      ["start_date"]: result.data.start_date && result.data.start_date,
      ["end_date"]: result.data.end_date && result.data.end_date,
      ["english_link"]: result.data.english_link && result.data.english_link,
      ["businessesMaster"]:
        result.data.businessesMaster.length > 0 && result.data.businessesMaster,
    }));

    setBusinessIdList(
      result.data.businessesMaster.length > 0 && result.data.businessesMaster
    );

    setCampaignImagesData(
      result.data.english_image_url.image_url !== null
        ? result.data.english_image_url.image_url
        : ""
    );
    setCampaignImagesIds(
      result.data.english_image_url.id !== null
        ? result.data.english_image_url.id
        : ""
    );

    let items = [...selectItems];
    result.data.weekday.length > 0 &&
      result.data.weekday.forEach((value, index) => {
        items.push(value.id);
      });

    items.length > 0 && items.map((k, i) => (weekdayOrder[0][i + 1] = k));
    setValues((prevState) => ({
      ...prevState,
      ["weekday"]: weekdayOrder,
    }));
    setSelectItems(items);
  };

  const handleInputChanged = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleLocationChanged = (event) => {
    const { name, value } = event.target;

    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "country_id") {
      setCountryId(value);
      getStateListData(value);

      setValues((prevState) => ({
        ...prevState,
        ["cities_id"]: "",
      }));

      setValues((prevState) => ({
        ...prevState,
        ["state_id"]: "",
      }));
    }

    if (name === "state_id") {
      getCityListData(countryId, value);
      setStateId(value);

      setValues((prevState) => ({
        ...prevState,
        ["cities_id"]: "",
      }));
    }
  };

  const handleChangeStartDate = (newValue) => {
    let startDate = moment(newValue).format("MM-DD-YYYY");
    let startDateformat = moment(newValue).format("YYYY-MM-DD");
    setStartDateValue(startDate);
    setValues({
      ...values,
      ["start_date"]: startDateformat,
      startDateValue,
    });
  };

  const handleChangeEndDate = (newValue) => {
    let endDate = moment(newValue).format("MM-DD-YYYY");
    let endDateformat = moment(newValue).format("YYYY-MM-DD");
    setEndDateValue(endDate);
    setValues({
      ...values,
      ["end_date"]: endDateformat,
    });
  };

  const handleDay = (item, index) => {
    let items = [...selectItems];
    setWeekdayOrder([{}]);
    let items2 = ([{}]);
    if (items.includes(item.id)) {
      let index = items.indexOf(item.id);
      items.splice(index, 1);
    } else {
      items.push(item.id);
    }
    items.map((k, i) => (items2[0][i + 1] = k));
    setSelectItems(items);
    setValues({
      ...values,
      ["weekday"]: items2,
    });
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      const reader = new FileReader();

      reader.onload = function (e) {
        var image = new Image();
        image.src = e.target.result;
        image.onload = function (e) {
          if (this.width < 350 || this.height < 300) {
            toast.error("File size must be equal or greater than 350*300");
          } else {
            setCampaignImages(file);
            imageUpload(file);
          }
        };
      };

      reader.readAsDataURL(file);
      return file;
    });
  });

  const onDropRejected = useCallback((files) => {
    toast.error("Please upload valid file png or jpg");
  });

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    onDropRejected,
    accept: "image/jpeg, image/jpg, image/png",
    maxFiles: 1
  });

  const showAddedList = (key) => {
    if (!businnesMasterId.includes(key.id)) {
      businnesMasterId.push(key.id);
    }

    return (
      <Row className={businessIdList.includes(key.id) ? "businessItems" : "businessItem"}>
        <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>{key.businessName}</Col>
        <Col className="text-end" xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
          <a data-key={key.id} type="primary" style={{ cursor: "pointer", color: "#19C1D6" }} onClick={(event) => handleBusinessRemove(event, key.id)}>Added</a>
        </Col>
      </Row>
    );
  };
  const imageUpload = async (id) => {
    const formData = new FormData();
    formData.append("filePath", id);
    formData.append("fileType", "I");

    const result = await postMethod(url.imageUpload, formData);
    setCampaignImagesData(result && result.data[0].filePath);
    setCampaignImagesIds(result && result.data[0].id);
    values.english_image_url.push(result && result.data[0].id);
  };

  const imageDelete = (data) => {
    setCampaignImages("");
    setCampaignImagesData("");
    setCampaignImagesIds("");
  };

  const handleBusinessAdd = async (event, key) => {
    const list = [...businessIdList];
    list.push(key);
    setBusinessIdList(list);
    handleSearchRemove();
  };

  const handleBusinessRemove = async (event, id) => {
    const list = [...businessIdList];
    const masterId = [...businnesMasterId];
    const index = list.findIndex((obj) => obj.id === id);

    list.splice(index, 1);
    masterId.splice(index, 1);
    setBusinessIdList(list);
    setBusinnesMasterId(masterId);
  };

  const postUpdateData = async () => {
    if (params.id === "create") {
      values["english_image_url"] = campaignImagesIds;
      values["promotion_type"] = params.type === "linkedbased" ? "link-based-promotion" : "association-based-promotion";
      const onClick = () => {
        const id = toast.success(result.data.response, () => { });
      };
      if (params.type !== "linkedbased") {
        values["businessesMaster"] = businnesMasterId;
      }
      const result = await postMethod(url.postCampaignData, values);
      if (result.status === 200 || result.status === 201) {
        onClick(result.data.response);
        navigate(-1);
      } else if (result.status === 400) {
        if (result.data.error.name && result.data.error.name[0]) {
          toast.error("Campaign Name may not be blank");
        } else if (result.data.error.city_id && result.data.error.city_id[0]) {
          toast.error("City Name may not be blank");
        } else if (
          result.data.error.country_id &&
          result.data.error.country_id[0]
        ) {
          toast.error("Country Name may not be blank");
        } else if (
          result.data.error.state_id &&
          result.data.error.state_id[0]
        ) {
          toast.error("State Name may not be blank");
        } else if (
          result.data.error.english_description &&
          result.data.error.english_description[0]
        ) {
          toast.error("Description may not be blank");
        } else if (
          result.data.error.start_date &&
          result.data.error.start_date[0]
        ) {
          toast.error("Start Date may not be blank");
        } else if (
          result.data.error.end_date &&
          result.data.error.end_date[0]
        ) {
          toast.error("End Date may not be blank");
        }
      } else {
        toast.error("Please fill all the mandatory fields");
      }
    } else {
      values["english_image_url"] = campaignImagesIds;
      const onClick = () => {
        const id = toast.success(result.data.response, () => { });
      };

      let body = {
        name: values.name,
        country_id: values.country_id,
        state_id: values.state_id,
        cities_id: values.cities_id,
        english_image_url: values.english_image_url,
        promotion_type: params.type === "linkedbased" ? "link-based-promotion" : "association-based-promotion",
        english_description: values.english_description,
        start_date: values.start_date,
        end_date: values.end_date,
        weekday: values.weekday,
        english_link: values.english_link,

        businessesMaster: businnesMasterId,
      };
      const result = await putMethod(
        url.putCampaignData + params.id + "/",
        body
      );
      if (result.status === 200 || result.status === 201) {
        onClick(result.data.response);
        navigate(-1);
      } else if (result.status === 400) {
        if (result.data.error.city_id && result.data.error.city_id[0]) {
          toast.error("City Name may not be blank");
        } else if (
          result.data.error.country_id &&
          result.data.error.country_id[0]
        ) {
          toast.error("Country Name may not be blank");
        } else if (
          result.data.error.state_id &&
          result.data.error.state_id[0]
        ) {
          toast.error("State Name may not be blank");
        } else if (
          result.data.error.start_date &&
          result.data.error.start_date[0]
        ) {
          toast.error("Start Date may not be blank");
        } else if (
          result.data.error.end_date &&
          result.data.error.end_date[0]
        ) {
          toast.error("End Date may not be blank");
        }
      } else {
        toast.error("Please fill all the mandatory fields");
      }
    }
  };
  const filter = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      const results = stateDataList.filter((user) => {
        return user.name.toLowerCase().startsWith(keyword.toLowerCase());
      });
      setStateList(results);
    } else {
      setStateList(stateDataList);
    }
  };

  const filters = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      const results = cityDataList.filter((user) => {
        return user.name.toLowerCase().startsWith(keyword.toLowerCase());
      });
      setCityList(results);
    } else {
      setCityList(cityDataList);
    }
  };

  return (
    <div className="event">
      <Navbar backBtn={true} />
      <div className="content_area">
        <div className="content_block d-flex justify-content-between">
          <div className="d-flex" style={{ alignItems: "center" }}>
            <div className="back_btn pe-4" style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="#000" Name="bi bi-arrow-left-short" viewBox="0 0 16 16" style={{ margin: "0px" }} >
                <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
              </svg>
            </div>
            <img style={{ width: "32px" }} src={params.type === "linkedbased" ? LinkedColor : AssociationColor} alt="" />
            <p className="d-inline-block m-0">{params.type === "linkedbased" ? "Link Based" : "Association Based"}</p>
          </div>
          <button className="commom_btn" onClick={postUpdateData}>{params.id !== "create" ? "Update" : "Create"}</button>
        </div>
        <Row>
          <div className="bg-white CommonForm">
            <Row>
              <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} className="pe-5 ps-4">
                <Row className="input_area">
                  <input type="text" placeholder="Name of the campaign" name="name" value={values.name} onChange={(e) => handleInputChanged(e)} />
                </Row>
                {params.type === "linkedbased" && (
                  <Row className="input_area">
                    <input type="text" placeholder="Enter Link" className="form-control" name={"english_link"} value={values.english_link} onChange={(e) => handleInputChanged(e)} />
                  </Row>
                )}

                <Row className="input_area">
                  <p>Validity</p>
                  <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} className="p-0 merge_input_first">
                    <FormControl
                      sx={{ m: 1, minWidth: "auto " }}
                      className="flex-row"
                    >
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                          <MobileDatePicker
                            inputFormat="MM-dd-yyyy"
                            value={moment(startDateValue).format("MM-DD-YYYY")}
                            onChange={handleChangeStartDate}
                            renderInput={(params) => (
                              <TextField placeholder="From" {...params} />
                            )}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </FormControl>
                  </Col>

                  <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} className="p-0 merge_input_second" >
                    <FormControl sx={{ m: 1, minWidth: "auto " }} className="input_picker flex-row">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                          <MobileDatePicker
                            inputFormat="MM-dd-yyyy"
                            value={moment(endDateValue).format("MM-DD-YYYY")}
                            onChange={handleChangeEndDate}
                            renderInput={(params) => (
                              <TextField placeholder="To" {...params} />
                            )}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </FormControl>
                  </Col>
                </Row>

                <Row className="input_area input_textarea">
                  <textarea
                    cols="30"
                    rows="5"
                    placeholder="Description"
                    name={"english_description"}
                    value={values.english_description}
                    onChange={(e) => handleInputChanged(e)}
                  ></textarea>
                </Row>
                {params.type !== "linkedbased" && (
                  <Row className="input_area" style={{ background: "#f2f2f2" }}>
                    <DebounceInput
                      minLength={1}
                      placeholder="Participating Businesses"
                      debounceTimeout={500}
                      value={searchText}
                      onChange={(e) => (
                        setSearchText(e.target.value),
                        searchBusinessList(e.target.value)
                      )}
                    />

                    <div className="isoSearchSelection">
                      {businessList.length > 0 &&
                        businessList.map((key, idx) => (
                          <Row className="ms-2 ps-1 pe-0 me-0" style={{ width: "100%" }}>
                            <Row style={{ border: "1px solid", padding: "10px" }}>
                              <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>{key.businessName}</Col>
                              <Col className="text-end" xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} >
                                {businessIdList.some(
                                  (el) => el.id === key.id
                                ) ? (
                                  <a data-key={key.id} type="primary" style={{ cursor: "pointer", color: "#19C1D6" }} onClick={(event) => handleBusinessRemove(event, key.id)}>Added</a>
                                ) : (
                                  <a data-key={key.id} type="primary" style={{ cursor: "pointer" }} onClick={(event) => handleBusinessAdd(event, key)} >Add </a>
                                )}
                              </Col>
                            </Row>
                          </Row>
                        ))}
                    </div>
                  </Row>
                )}
              </Col>

              <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                <Row className="input_area">
                  <FormControl sx={{ m: 1, minWidth: "100%" }} className="custom_dropdown">
                    <InputLabel id="Country">Country</InputLabel>
                    <Select
                      labelId="Country"
                      placeholder="Country"
                      id="Select"
                      name="country_id"
                      value={values.country_id}
                      defaultValue={values.country_id}
                      onChange={(e) => handleLocationChanged(e)}
                    >
                      {countryList.map((key, idx) => (
                        <MenuItem value={key.id}>{key.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Row>
                <Row className="input_area">
                  <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} className="ps-0">
                    <FormControl sx={{ minWidth: "100%" }} className="custom_dropdown">
                      <InputLabel id="search-select-label">State</InputLabel>
                      <Select
                        MenuProps={{ autoFocus: false }}
                        labelId="search-select-label"
                        id="search-select"
                        name="state_id"
                        value={values.state_id}
                        defaultValue={values.state_id}
                        label="Options"
                        onChange={(e) => handleLocationChanged(e)}
                        onClose={() => setStateList(stateDataList)}
                      >
                        <ListSubheader>
                          <TextField
                            size="small"
                            autoFocus
                            placeholder="Type to search..."
                            fullWidth
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                            onChange={filter}
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                e.stopPropagation();
                              }
                            }}
                          />
                        </ListSubheader>

                        {stateList && stateList.length > 0 && stateList.map((key) => (
                          <MenuItem value={key.id}>{key.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} className="pe-0">
                    <FormControl sx={{ m: 1, minWidth: "100%" }} className="custom_dropdown">
                      <InputLabel id="search-select-label">City</InputLabel>
                      <Select
                        MenuProps={{ autoFocus: false }}
                        labelId="search-select-label"
                        id="search-select"
                        name="cities_id"
                        value={values.cities_id}
                        defaultValue={values.cities_id}
                        label="Options"
                        onChange={(e) => handleLocationChanged(e)}
                        onClose={() => setCityList(cityDataList)}
                      >
                        <ListSubheader>
                          <TextField
                            size="small"
                            autoFocus
                            placeholder="Type to search..."
                            fullWidth
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                            onChange={filters}
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                e.stopPropagation();
                              }
                            }}
                          />
                        </ListSubheader>
                        {cityList && cityList.length > 0 && cityList.map((key) => (
                          <MenuItem key={key.id} value={key.id}>
                            {key.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Col>
                </Row>
                <Row className="input_area">
                  <p className="select_days">Select the days</p>
                  <div className="week d-flex justify-content-between">
                    {weekDays.map((day, index) => {
                      return (
                        <div className={`day w-100 me-3 ${selectItems.includes(day.id) ? "localColor" : "abc"}`} onClick={() => handleDay(day, index)} >
                          {day.name}
                        </div>
                      );
                    })}
                  </div>
                </Row>
                <Row className="input_area">
                  <Col className="p-0">
                    <p className="dropzone_heading mb-3">Upload banner image</p>
                    <div className="container p-0">
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        {isDragAccept && <p>All files will be accepted</p>}
                        {isDragReject && <p>Some files will be rejected</p>}
                        {!isDragActive && (
                          <div className="text-center">
                            <img src={uploadicon} alt="" />
                            <Row>
                              <p className="m-0">Drag and Drop or <div>browse</div></p>
                            </Row>
                            <Row>
                              <span>Only png, jpg is supported</span>
                              <span>Minimum Image Resolution Should be 350*300</span>
                            </Row>
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                {campaignImagesData && campaignImagesData && (
                  <Row className="input_area">
                    <Col className="p-0">
                      <div className="container p-0">
                        <img src={campaignImagesData} style={{ width: "100%", height: "250px" }} />
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
              {params.type !== "linkedbased" && businessIdList.length > 0 && (
                <Col className=" ms-2 " xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  {businessIdList.length > 0 && businessIdList.map((key, idx) => showAddedList(key))}
                </Col>
              )}
            </Row>
          </div>
        </Row>
      </div>
    </div>
  );
}
