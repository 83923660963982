import React, { useCallback, useState, useEffect, useRef } from "react";
import { InputLabel, MenuItem, Select, Stack, TextField, } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import "./dropzoneComponent.scss";
import FormControl from "@mui/material/FormControl";
import { useNavigate } from "react-router-dom";
import uploadicon from "../../../assets/images/uploadicon.svg";
import mapicon from "../../../assets/images/mapicon.svg";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker, MobileTimePicker, TimePicker, } from "@mui/x-date-pickers";
import { getMethod, postMethod } from "../../../utils/apis/services/apis";
import url from "../../../utils/apis/services/urls.json";
import moment from "moment";
import { useParams } from "react-router";
import ToastService from "react-material-toast";
import { DebounceInput } from "react-debounce-input";
import CreatableSelect from 'react-select/creatable';

const toast = ToastService.new({
  place: "topRight",
  duration: 2,
  maxCount: 4,
});

const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label,
});

export default function DropZoneComponent(props) {
  const params = useParams();
  const navigate = useNavigate();
  const dragItem = useRef();
  const dragOverItem = useRef();
  const forceUpdate = useForceUpdate();
  const [startDateValue, setStartDateValue] = React.useState(new Date(""));
  const [endDateValue, setEndDateValue] = React.useState(new Date(""));
  const [startTimeValue, setStartTimeValue] = React.useState(new Date(""));
  const [endTimeValue, setEndTimeValue] = React.useState(new Date(""));
  const [countryId, setCountryId] = useState("");
  const [cityId, setCityId] = useState("");
  const [files, setFiles] = useState([]);
  const [uploadImageUrl, setUploadImageUrl] = useState([]);
  const [checkData, setCheckData] = useState([]);
  const [businessName, setBusinessName] = useState("");
  const [inputValue, setInputValue] = React.useState('');
  const [selectValue, setSelectValue] = React.useState([]);

  const [values, setValues] = useState({
    postName: "",
    bussinessName: "",
    bussinessID: "",
    location: "",
    addTags: selectValue.map((k) => k.value),
    description: "",
    country: "",
    state: "",
    stateID: "",
    city: "",
    image: [],
    countryID: "",
    cityID: "",
    imageID: [],
    videoID: [],
    days_list: [],
    valid_from: "",
    valid_to: "",
    start_time: "",
    end_time: "",
    entryPrice: "",
    purchaseLink: ""
  });

  const SearchBusinessList = async (value) => {
    if (value !== "") {
      const result = await getMethod(url.searchBusinessList + value.toLowerCase());
      setBussinessData(result?.data?.results);
    } else {
      handleSearchRemoves();
    }
  };
  const handleChangeEndDate = (newValue) => {
    let endDate = moment(newValue).format("MM-DD-YYYY");
    let endDateformat = moment(newValue).format("YYYY-MM-DD");
    setEndDateValue(endDate);
    setValues({
      ...values,
      ["valid_to"]: endDateformat,
    });
  };

  const handleChangeStartDate = (newValue) => {
    let startDate = moment(newValue).format("MM-DD-YYYY");
    let startDateformat = moment(newValue).format("YYYY-MM-DD");
    setStartDateValue(startDate);
    setValues({
      ...values,
      ["valid_from"]: startDateformat,
    });
  };

  const handleChangeTime = (newValue) => {
    let startTime = moment(newValue).format("HH:mm:ss");
    setStartTimeValue(startTime);
    setValues({
      ...values,
      ["start_time"]: startTime,
    });
  };

  const handleChangeEndTime = (newValue) => {
    let endTime = moment(newValue).format("HH:mm:ss");
    setEndTimeValue(endTime);
    setValues({
      ...values,
      ["end_time"]: endTime,
    });
  };
  const onDrop = useCallback((acceptedFiles) => {
    const allFiles = [...files, ...acceptedFiles];
    setFiles(allFiles);

    acceptedFiles.map((file) => {
      const reader = new FileReader();

      reader.onload = function (e) {
        var image = new Image();
        image.src = e.target.result;
        image.onload = function (e) {
          if (this.width < 350 || this.height < 300) {
            toast.error("File size must be equal or greater than 350*300");
          } else {
            imageUpload(file);
          }
        };
      };

      reader.readAsDataURL(file);
      return file;
    });
  }, []);

  const onDropRejected = useCallback((files) => {
    if (files[0].errors[0]) {
      toast.error("File size must be equal or greater than 350*300");
    }
  });

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    onDropRejected,
    accept: {
      "image/jpeg": [".jpeg", ".png", "video/*"],
    },
  });

  const handleChanges = (value) => {
    setBusinessName(value.businessName);
    let index = bussinessData.findIndex((obj) => obj.address === value.address);

    setValues({
      ...values,
      bussinessName: [value.businessName],
      location: bussinessData[index].address,
      country: bussinessData[index].country.name,
      state: bussinessData[index].state.name,
      city: bussinessData[index].city.name || bussinessData[index].city.city,
      countryID: bussinessData[index].country.id,
      stateID: bussinessData[index].state.id,
      cityID: bussinessData[index].city.id,
      bussinessID: bussinessData[index].id,
    });
  };

  useEffect(() => {
    setSelectItems(Week.map((item) => item.slug));
    setValues({
      ...values,
      ["days_list"]: Week.map((item) => item.slug),
    });
  }, []);

  const [Week, setWeek] = useState([
    {
      id: 1,
      slug: "sunday",
      icon: "S",
    },
    {
      id: 2,
      slug: "monday",
      icon: "M",
    },
    {
      id: 3,
      slug: "tuesday",
      icon: "T",
    },
    {
      id: 4,
      slug: "wednesday",
      icon: "W",
    },
    {
      id: 5,
      slug: "thursday",
      icon: "Th",
    },
    {
      id: 6,
      slug: "friday",
      icon: "F",
    },
    {
      id: 7,
      slug: "saturday",
      icon: "S",
    },
  ]);

  const [selectItems, setSelectItems] = useState([]);
  const [bussinessData, setBussinessData] = useState([]);

  const handleDay = (item, index) => {
    let items = [...selectItems];
    setSelectItems(items);
    if (items.includes(item.slug)) {
      let index = items.indexOf(item.slug);
      items.splice(index, 1);
    } else {
      items.push(item.slug);
    }
    setValues({
      ...values,
      ["days_list"]: items,
    });
  };

  const handleChanged = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    props.postingData(values, countryId, cityId, checkData);
  }, [values]);

  function useForceUpdate() {
    const [value, setValue] = useState(0);
    return () => setValue((value) => value + 1);
  }

  const imageUpload = async (id) => {
    const formData = new FormData();
    formData.append("filePath", id);

    if (id.type === "video/mp4") {
      formData.append("fileType", "V");
    } else {
      formData.append("fileType", "I");
    }
    const result = await postMethod(url.imageUpload, formData);
    uploadImageUrl.push(result.data[0]);
    checkData.push(result.data[0].id);

    if (result.data[0].fileType === "I") {
      values.imageID.push({ imageId: result.data[0].id, orderNumber: 0 });
    } else {
      values.videoID.push(result.data[0].id);
    }
    forceUpdate();
  };

  const imageDelete = (data) => {
    let requiredIndexToDelete = uploadImageUrl.indexOf(data);
    uploadImageUrl.splice(requiredIndexToDelete, 1);
    let requiredIdIndex = checkData.indexOf(data.id);
    checkData.splice(requiredIdIndex, 1);
    values.imageID.splice(requiredIdIndex, 1);
    forceUpdate();
  };

  const handlePostUpdate = async () => {
    const result = await getMethod(
      url.getAllTypePostDetails + params.id + "/" + props.method
    );

    if (result.status === 401) {
      toast.error(result.data.detail);
      navigate("/");
    }

    result.data.image_ids.map((item) => {
      uploadImageUrl.push(item);
      if (item.fileType === "I") {
        values.imageID.push({
          imageId: item.id,
          orderNumber: item.orderNumber,
        });
      } else if (item === "V") {
        values.videoID.push(item.id);
      }
      checkData.push(item.length);
    });
    result.data.video_ids.map((item) => {
      uploadImageUrl.push(item);
      if (item.fileType === "I") {
        values.imageID.push(item.id);
      } else if (item === "V") {
        values.videoID.push(item.id);
      }
      checkData.push(item.length);
    });

    setStartTimeValue(result.data.start_time && result.data.start_time)
    setEndTimeValue(result.data.end_time && result.data.end_time);
    setStartDateValue(result?.data?.valid_from);
    setEndDateValue(result?.data?.valid_to);
    setSelectItems(result.data.days && result.data.days.map((key) => key.slug));
    setBusinessName(result.data.business.businessName);
    result.data.tag_list.length > 0 && result.data.tag_list.map((key) => setSelectValue((prev) => [...prev, createOption(key.name)]));

    setValues({
      ...values,
      postName: result.data.postName,
      bussinessName: result.data.business.businessName,
      bussinessID: result.data.business.id,
      location: result.data.location,
      addTags: result.data.tag_list.map((key) => key.name),
      description: result.data.description,
      country: result.data.country.name,
      state: result.data.city && result.data.state.name,
      city: result.data.city && result.data.city.name,
      image: result.data.business.businessName,
      countryID: result.data.country.id,
      stateID: result.data.city && result.data.state.id,
      cityID: result.data.city && result.data.city.id,
      imageID: values.imageID,
      videoID: values.videoID,
      days_list: result.data.days && result.data.days.map((key) => key.slug),
      valid_from: result?.data?.valid_from,
      valid_to: result?.data?.valid_to,
      start_time: result.data.start_time,
      end_time: result.data.end_time,
      entryPrice: result.data.entryPrice,
      purchaseLink: result.data.purchaseLink,
    });
  };

  useEffect(() => {
    if (params.id !== "create") {
      handlePostUpdate();
    }
  }, []);

  function debounce(func, timeout = 1000) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  const handleSearchRemove = () => {
    setBussinessData([]);
  };
  const handleSearchRemoves = () => {
    setBussinessData([]);
    setBusinessName("");
    setValues({
      ...values,
      location: "",
      country: "",
      state: "",
      city: "",
    });
  };

  const handleBusinessAdd = async (event, key) => {
    handleChanges(key);
    handleSearchRemove();
  };

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = (e) => {
    const copyListItems = [...uploadImageUrl];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setUploadImageUrl(copyListItems);
    const data = copyListItems.map((key, index) => {
      return {
        imageId: key.id,
        orderNumber: index + 1,
      };
    });
    setValues({ ...values, ["imageID"]: data });
  };


  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
      case ',':
        setSelectValue((prev) => [...prev, createOption(inputValue)]);
        setInputValue('');
        event.preventDefault();
    }
  };

  return (
    <div className="bg-white CommonForm">
      <Row>
        <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} className="pe-5 ps-4">
          <Row className="input_area" style={{ background: "#f2f2f2" }}>
            {params.id === "create" && (
              <DebounceInput
                minLength={1}
                placeholder="Business Name"
                debounceTimeout={500}
                onChange={(e) => SearchBusinessList(e.target.value)}
                value={businessName}
              />
            )}
            {params.id !== "create" && (
              <TextField
                size="small"
                disabled
                placeholder="Business Name"
                value={businessName}
              />
            )}
            <div className="isoSearchSelection">
              {bussinessData.length > 0 &&
                bussinessData.map((key, idx) => {
                  return (
                    <Row className="ms-2 ps-1 pe-0 me-0" style={{ width: "100%", cursor: "pointer" }} onClick={(event) => handleBusinessAdd(event, key)}>
                      <Row style={{ border: "1px solid", padding: "10px" }}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>{key.businessName}</Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>{key.address}</Col>
                      </Row>
                    </Row>
                  )
                })}
            </div>
          </Row>

          <Row className="input_area">
            <div className="input-group mb-3 p-0">
              <input type="text" placeholder="Location" className="form-control" disabled aria-label="Amount (to the nearest dollar)" value={values.location} name={"location"} onChange={(e) => handleChanged(e)} />
              <div className="input-group-append m-auto ">
                <img src={mapicon} alt="iconmap" className="iconmap" />
              </div>
            </div>
          </Row>

          {props.field &&
            <Row className="input_area">
              <input type="text" placeholder="Title" name={"postName"} value={values.postName} onChange={(e) => handleChanged(e)} />
            </Row>
          }

          {props.field ? (
            <Row className="input_area">
              <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} className="p-0 merge_input_first">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack spacing={3}>
                    <MobileTimePicker
                      mask="__:__:__"
                      ampmInClock={true}
                      inputFormat="HH:mm:ss"
                      views={["hours", "minutes", "seconds"]}
                      value={`Mon Sep 19 2022 ${startTimeValue}`}
                      onChange={handleChangeTime}
                      renderInput={(params) => (
                        <TextField placeholder="start Time" {...params} />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} className="p-0 merge_input_second">
                <FormControl sx={{ m: 1, minWidth: "auto" }} className="input_picker flex-row">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack spacing={3}>
                      <MobileTimePicker
                        mask="__:__:__"
                        ampmInClock={true}
                        inputFormat="HH:mm:ss"
                        views={["hours", "minutes", "seconds"]}
                        value={`Mon Sep 19 2022 ${endTimeValue}`}
                        onChange={handleChangeEndTime}
                        renderInput={(params) => (
                          <TextField placeholder="End Time" {...params} />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </FormControl>
              </Col>
            </Row>
          ) : (
            ""
          )}

          {props.method === "post-event" &&
            <Row className="input_area">
              <input type="number" placeholder="Entry Price" name={"entryPrice"} value={values.entryPrice} onChange={(e) => handleChanged(e)} />
            </Row>
          }

          <Row className="input_area">
            <CreatableSelect
              components={components}
              inputValue={inputValue}
              isClearable
              isMulti
              className="isoTagSelectClass"
              classNamePrefix="localiso"
              name={"addTags"}
              menuIsOpen={false}
              onChange={(newValue) => setSelectValue(newValue)}
              onInputChange={(newValue) => (setInputValue(newValue), setValues({ ...values, ["addTags"]: selectValue.map((k) => k.value) }))}
              onKeyDown={handleKeyDown}
              placeholder="Add Tags"
              value={selectValue}
            />
          </Row>

          <Row className="input_area input_textarea">
            <textarea
              name={"description"}
              id=""
              cols="30"
              rows="5"
              placeholder={"Comments/Description"}
              value={values.description}
              onChange={(e) => handleChanged(e)}
            ></textarea>
          </Row>
        </Col>
        <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
          <Row className="input_area">
            <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
              <FormControl sx={{ m: 1, minWidth: "100%" }} className="custom_dropdown">
                <InputLabel id="Input label">Country</InputLabel>
                <Select
                  labelId="Input label"
                  id="Select"
                  value={values.country}
                  onChange={(e) => handleChanges(e.target.value)}
                >
                  <MenuItem disabled value={values.country}>{values.country}</MenuItem>
                </Select>
              </FormControl>
            </Col>
            <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
              <FormControl sx={{ m: 1, minWidth: "100%" }} className="custom_dropdown">
                <InputLabel id="Input label">State</InputLabel>
                <Select
                  labelId="Input label"
                  id="Select"
                  value={values.state}
                  onChange={(e) => handleChanges(e.target.value)}
                >
                  <MenuItem disabled value={values.state}>{values.state}</MenuItem>
                </Select>
              </FormControl>
            </Col>
            <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
              <FormControl sx={{ m: 1, minWidth: "100%" }} className="custom_dropdown">
                <InputLabel id="Input label">City</InputLabel>
                <Select
                  labelId="Input label"
                  id="Select"
                  value={values.city}
                  onChange={(e) => handleChanges(e.target.value)}
                >
                  <MenuItem disabled value={values.city}>{values.city}</MenuItem>
                </Select>
              </FormControl>
            </Col>
          </Row>

          {props.method === "post-event" &&
            <Row className="input_area">
              <input type="text" placeholder="Ticket Link" name={"purchaseLink"} value={values.purchaseLink} onChange={(e) => handleChanged(e)} />
            </Row>
          }

          {props.field ? (
            <Row className="input_area">
              <p className="select_days">Select the days</p>
              <div className="week d-flex justify-content-between">
                {Week.map((day, index) => {
                  return (
                    <div className={`day w-100 me-3 ${selectItems.includes(day.slug) ? "localColor" : "abc"}`} onClick={() => handleDay(day, index)}>{day.icon} </div>
                  )
                })}
              </div>
            </Row>
          ) : (
            ""
          )}

          {props.field ? (
            <Row className="input_area datepicker">
              <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} className="p-0 merge_input_first">
                <FormControl sx={{ m: 1, minWidth: "auto " }} className="flex-row">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack spacing={3}>
                      <MobileDatePicker
                        inputFormat="MM-dd-yyyy"
                        value={moment(startDateValue).format("MM-DD-YYYY")}
                        onChange={handleChangeStartDate}
                        renderInput={(params) => (
                          <TextField placeholder="Start Date" {...params} />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </FormControl>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} className="p-0 merge_input_second">
                <FormControl sx={{ m: 1, minWidth: "auto " }} className="input_picker flex-row">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack spacing={3}>
                      <MobileDatePicker
                        inputFormat="MM-dd-yyyy"
                        value={moment(endDateValue).format("MM-DD-YYYY")}
                        onChange={handleChangeEndDate}
                        renderInput={(params) => (
                          <TextField placeholder="End Date" {...params} />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </FormControl>
              </Col>
            </Row>
          ) : (
            ""
          )}
          <Row className="input_area">
            <Col className="p-0">
              <p className="dropzone_heading mb-2">Upload image</p>
              <div className="container p-0">
                <div className="totalImg text-center" data-bs-toggle="modal" data-bs-target="#exampleModal">{checkData.length} </div>
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  {isDragAccept && <p>All files will be accepted</p>}
                  {isDragReject && <p>Some files will be rejected</p>}
                  {!isDragActive && (
                    <div className="text-center">
                      <img src={uploadicon} alt="" />
                      <Row>
                        <p className="m-0">
                          Drag and Drop or <div>browse</div>
                        </p>
                      </Row>
                      <Row>
                        <span>Only png, jpg is supported</span>
                        <span>Minimum Image Resolution Should be 350*300</span>
                      </Row>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* modal----------======== */}
      <div class="modal " id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <Row className="input_area">
                <Col className="d-flex manage_flex">
                  <div className="container p-0">
                    <div {...getRootProps({ className: "dropzone d-inline" })}>
                      <input {...getInputProps()} />
                      {isDragAccept && <p>All files will be accepted</p>}
                      {isDragReject && <p>Some files will be rejected</p>}
                      {!isDragActive && (
                        <i class="bi bi-plus align-self-center w-100 " data-bs-toggle="modal" data-bs-target="#exampleModal"></i>
                      )}
                    </div>
                  </div>
                  {uploadImageUrl &&
                    uploadImageUrl.map((data, index) => {
                      return (
                        <div className="upload " onDragStart={(e) => dragStart(e, index)} onDragEnter={(e) => dragEnter(e, index)} onDragEnd={drop} key={index} draggable>
                          <div className="crossBtn" onClick={() => imageDelete(data)} >
                            <i class="bi bi-x"></i>
                          </div>
                          {data.fileType === "I" ? (
                            <img src={data.filePath} alt="nothing" />
                          ) : (
                            <video width="100%" height="100%" maxHeight="100px" maxWidth="120px" controls src={data.filePath} />
                          )}
                        </div>
                      );
                    })}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
      {/* modal----------======== */}
    </div>
  );
}
