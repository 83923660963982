import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Row, Col, Button } from "react-bootstrap";
import BannerA from "../../assets/images/banner/BannerA.jpg";
import BannerB from "../../assets/images/banner/BannerB.jpg";
import BannerC from "../../assets/images/banner/BannerC.jpg";
import BannerD from "../../assets/images/banner/BannerD.jpg";
import BannerE from "../../assets/images/banner/BannerE.jpg";
import BannerF from "../../assets/images/banner/BannerF.jpg";
import BannerG from "../../assets/images/banner/BannerG.jpg";
import locallogo from "../../assets/images/berb-logo.svg";
import { TextField, Checkbox } from "@mui/material";
import "./login.scss";
import "../../../src/index.scss";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import { useNavigate } from "react-router-dom";
import { getMethod, postWithoutTokenMethod } from "../../utils/apis/services/apis";
import url from "../../utils/apis/services/urls.json";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useForm, Controller } from "react-hook-form";
import ToastService from "react-material-toast";
import { setUserSession, setUserEmailSession } from "../../utils/apis/services/common";
import Loader from "../loader/Loader";
import { Audio } from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const toast = ToastService.new({
  place: "topRight",
  duration: 2,
  maxCount: 4,
});
export default function Login() {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isInitialValid, setIsInitialValid] = useState(false);
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const passwordRegex = /^([a-zA-Z0-9?=^@$#]).{8,}$/;
  const [errorText, setErrorText] = useState();
  const [errorPassword, setErrorPassword] = useState();
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");

  const postLogin = async () => {
    const body = {
      username: emailAddress,
      password: password,
    };
    setLoading(true);
    const response = await postWithoutTokenMethod(url.getLogin, body);
    const onErrorClick = () => {
      const id = toast.error(response.data.error);
    };
    if (response.status === 200) {
      setUserEmailSession(emailAddress);
      navigate("./Otp_Verification");
    } else {
      onErrorClick();
    }
    setLoading(false);
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
  };

  const onChange = (event) => {
    setEmailAddress(event.target.value);
  };

  function utf8_to_b64(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
  }

  const onChangePassword = (event) => {
    let password = utf8_to_b64(event.target.value);
    setPassword(password);
  };

  const { register, handleSubmit, errors } = useForm();

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Row className="login h-100vh">
          <Col xs={12} sm={12} md={4} lg={5} xl={5} className="bannerSide">
            <Col xs={12} sm={12} md={4} lg={5} xl={5} className="upper text-center align-self-center">
              <img className="localLogo" src={locallogo} alt="logo" />
            </Col>
            <Slider {...settings} className="text-end">
              <div>
                <img className="banner" src={BannerA} alt="" />
              </div>
              <div>
                <img className="banner" src={BannerB} alt="" />
              </div>
              <div>
                <img className="banner" src={BannerC} alt="" />
              </div>
              <div>
                <img className="banner" src={BannerD} alt="" />
              </div>
              <div>
                <img className="banner" src={BannerE} alt="" />
              </div>
              <div>
                <img className="banner" src={BannerF} alt="" />
              </div>
              <div>
                <img className="banner" src={BannerG} alt="" />
              </div>
            </Slider>
          </Col>
          <Col xs={12} sm={12} md={8} lg={7} xl={7} className="form-side  m-auto" >
            <Formik>
              {({ errors, touched, isValid, initialValues }) => (
                <Form onSubmit={handleSubmit(postLogin)}>
                  <Row>
                    <h3>Log In</h3>
                    <TextField
                      required
                      name="email"
                      id="email"
                      placeholder="Email Id"
                      variant="standard"
                      className="email_field"
                      helperText={errorText}
                      error={errorText}
                      onChange={onChange}
                    />
                    <TextField
                      required
                      className="password_field"
                      id="password"
                      placeholder="Password"
                      type="password"
                      autoComplete="current-password"
                      variant="standard"
                      helperText={errorPassword}
                      error={errorPassword}
                      onChange={onChangePassword}
                    />
                    <div className="d-flex p-0 checkbox_field">
                      <Col className="d-inline-block">
                      </Col>
                      <Col className="forgetPassword text-end d-inline-block" onClick={() => navigate("./forgetpassword")} >Forgot Password?</Col>
                    </div>
                    <Button className="continue_button" type="submit">Log In</Button>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      )}
    </>
  );
}
