import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Navbar from "../../components/common/navbar/Navbar";
import defaultImage from "../../assets/images/defaultImage.png";
import deleteicon from "../../assets/images/delete.svg";
import editpen from "../../assets/images/editpen.svg";
import Switch from "@mui/material/Switch";
import "./post.scss";
import { useState } from "react";
import { useNavigate } from "react-router";
import { deleteMethod, getMethod, putMethod } from "../../utils/apis/services/apis";
import url from "../../utils/apis/services/urls.json";
import { useParams } from "react-router";
import ToastService from "react-material-toast";
import fadeupdate from "../../assets/images/iconSidebar/fadeupdate.png";
import fadespecial from "../../assets/images/iconSidebar/fadespecial.png";
import fadeevent from "../../assets/images/iconSidebar/fadeevent.png";
import { confirmAlert } from "react-confirm-alert";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "../../screens/loader/Loader";
import Pagination from "react-js-pagination";
import Stack from "@mui/material/Stack";
import "../../components/common/common.scss";
import SearchIcon from "../../assets/images/10-icon.svg";

const toast = ToastService.new({
  place: "topRight",
  duration: 2,
  maxCount: 4,
});

export default function Post() {
  const navigate = useNavigate();
  const params = useParams();
  const [selectedItems, setSelectedItems] = useState("");
  const [allPostListData, setAllPostListData] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [initialPageNO, setInitialPageNO] = useState(false);
  const [count, setCount] = useState(1);
  const [searchText, setSearchText] = useState("");

  var [activePage, setPage] = useState(
    parseInt(localStorage.getItem("PageNo") ? localStorage.getItem("PageNo") : 1)
  );


  const post = [
    {
      id: 1,
      name: "Update",
      icon: "fade_update",
      route: "./post-update/create",
    },
    {
      id: 2,
      name: "Event",
      icon: "fade_event",
      route: "./post-event/create",
    },
    {
      id: 3,
      name: "Special",
      icon: "fade_special",
      route: "./post-special/create",
    },
  ];

  const selectItem = (item, index) => {
    let items = [...selectedItems];
    setSelectedItems(items);
    if (items.includes(item)) {
      let index = items.indexOf(item);
      items.splice(index, 1);
    } else {
      items.pop(item);
      items.push(item);
    }
  };

  const counts = Math.ceil(count / 20);

  const handlePageChange = async (event) => {
    setInitialPageNO(true);
    setLoading(true);
    setLoadingPage(true);
    localStorage.setItem("PageNo", event);
    setPage(event);

    var status = "";
    if (params.id === ":updatelist") {
      status = "post-update";
    } else if (params.id === ":eventlist") {
      status = "post-event";
    } else if (params.id === ":speciallist") {
      status = "post-special";
    }
    const result = await getMethod(
      url.getAllTypePosts + "?page=" + event + "&post=" + status + "&searchtext=" + searchText.toLowerCase()
    );
    setAllPostListData(result.data.results.data);
    setLoading(false);
    setLoadingPage(false);
  };

  const handleSearchChanged = async (event) => {
    setSearchText(event.target.value)
  };

  const handleInputSearchList = async (event) => {
    if (event.keyCode === 13) {
      setInitialPageNO(true)
      setLoading(true);
      setLoadingPage(true);
      localStorage.setItem("PageNo", 1);
      setPage(1);

      if (params.id === ":create") {
        const result = await getMethod(url.getAllTypePost + "&page=1" + "&searchtext=" + searchText.toLowerCase());
        setAllPostListData(result.data.results.data);
        setCount(result.data.count);
      } else if (params.id === ":updatelist") {
        const result = await getMethod(url.getAllTypePost + "post-update&page=1" + "&searchtext=" + searchText.toLowerCase());
        setAllPostListData(result.data.results.data);
        setCount(result.data.count);
      } else if (params.id === ":eventlist") {
        const result = await getMethod(url.getAllTypePost + "post-event&page=1" + "&searchtext=" + searchText.toLowerCase());
        setAllPostListData(result.data.results.data);
        setCount(result.data.count);
      } else if (params.id === ":speciallist") {
        const result = await getMethod(url.getAllTypePost + "post-special&page=1" + "&searchtext=" + searchText.toLowerCase());
        setAllPostListData(result.data.results.data);
        setCount(result.data.count);
      }

      setLoading(false);
      setLoadingPage(false);
    }
  };


  const handleSearchList = async () => {
    setInitialPageNO(true)
    setLoading(true);
    setLoadingPage(true);
    localStorage.setItem("PageNo", 1);
    setPage(1);

    if (params.id === ":create") {
      const result = await getMethod(url.getAllTypePost + "&page=1" + "&searchtext=" + searchText.toLowerCase());
      setAllPostListData(result.data.results.data);
      setCount(result.data.count);
    } else if (params.id === ":updatelist") {
      const result = await getMethod(url.getAllTypePost + "post-update&page=1" + "&searchtext=" + searchText.toLowerCase());
      setAllPostListData(result.data.results.data);
      setCount(result.data.count);
    } else if (params.id === ":eventlist") {
      const result = await getMethod(url.getAllTypePost + "post-event&page=1" + "&searchtext=" + searchText.toLowerCase());
      setAllPostListData(result.data.results.data);
      setCount(result.data.count);
    } else if (params.id === ":speciallist") {
      const result = await getMethod(url.getAllTypePost + "post-special&page=1" + "&searchtext=" + searchText.toLowerCase());
      setAllPostListData(result.data.results.data);
      setCount(result.data.count);
    }

    setLoading(false);
    setLoadingPage(false);
  }

  const getAllTypePost = async () => {
    setPage(parseInt(localStorage.getItem("PageNo") ? localStorage.getItem("PageNo") : 1));

    const curPage = parseInt(localStorage.getItem("PageNo") ? localStorage.getItem("PageNo") : 1);

    if (params.id === ":create") {
      setLoading(true);
      let result = await getMethod(url.getAllTypePost + "&page=" + curPage);
      if (result.status === 401) {
        toast.error(result.data.detail);
        navigate("/");
      }
      setAllPostListData(result.data.results.data);
      setCount(result.data.count);
      setLoading(false);
    } else if (params.id === ":updatelist") {
      setLoading(true);
      let result = await getMethod(url.getAllTypePost + "post-update&page=" + curPage);
      if (result.status === 401) {
        toast.error(result.data.detail);
        navigate("/");
      }
      setAllPostListData(result.data.results.data);
      setCount(result.data.count);
      setLoading(false);
    } else if (params.id === ":eventlist") {
      setLoading(true);
      let result = await getMethod(url.getAllTypePost + "post-event&page=" + curPage);
      if (result.status === 401) {
        toast.error(result.data.detail);
        navigate("/");
      }
      setAllPostListData(result.data.results.data);
      setCount(result.data.count);
      setLoading(false);
    } else if (params.id === ":speciallist") {
      setLoading(true);
      let result = await getMethod(url.getAllTypePost + "post-special&page=" + curPage);
      if (result.status === 401) {
        toast.error(result.data.detail);
        navigate("/");
      }
      setAllPostListData(result.data.results.data);
      setCount(result.data.count);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllTypePost();
    setSearchText("");
  }, [params.id === ":updatelist", params.id === ":eventlist",
  params.id === ":speciallist", params.id === ":create"]);

  const handleUpdate = (id, postType) => {
    navigate(`/post:updatelist/${postType}/${id}`);
  };

  const postStatusUpdate = async (id, value, type) => {
    let body = {
      isActive: value,
    };
    var statusUpdateUrl = "";

    if (params.id === ":create") {
      if (type === "post-update") {
        statusUpdateUrl = url.putPostUpdate + id;
      } else if (type === "post-event") {
        statusUpdateUrl = url.putPostEvent + id;
      } else if (type === "post-special") {
        statusUpdateUrl = url.putPostSpecial + id;
      }
      const result = await putMethod(statusUpdateUrl, body);
      ShowMessage(result);
    } else if (params.id === ":updatelist") {
      const result = await putMethod(url.putPostUpdate + id, body);
      ShowMessage(result);
    } else if (params.id === ":eventlist") {
      const result = await putMethod(url.putPostEvent + id, body);
      ShowMessage(result);
    } else if (params.id === ":speciallist") {
      const result = await putMethod(url.putPostSpecial + id, body);
      ShowMessage(result);
    }
    getAllTypePost();
  };

  const ShowMessage = (result) => {
    if (result.status === 200) {
      toast.success(result.data.response);
    } else {
      toast.error(result.request.statusText);
    }
  };

  const deleteCampaign = async (id, postType) => {
    if (postType === "post-update") {
      let result = await deleteMethod(url.deletePostUpdate + id);
      ShowMessage(result);
    } else if (postType === "post-event") {
      let result = await deleteMethod(url.deletePostEvent + id);
      ShowMessage(result);
    } else if (postType === "post-special") {
      let result = await deleteMethod(url.deletePostSpecial + id);
      ShowMessage(result);
    }
    getAllTypePost();
  };

  const deleteConfirm = async (id, postType, name) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to delete " + name + "?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteCampaign(id, postType);
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  return (
    <>
      <div className="post">
        <Navbar backBtn={true} />
        <div className="content_area">
          <div className="content_block">
            <Row>
              <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                <Row>
                  <div>
                    <i className="bi bi-plus-circle"></i>
                    <p className="d-inline-block">Post</p>
                  </div>
                </Row>
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                <Row className="input_area business-search">
                  <div className="input-group mb-3 p-0 ">
                    <input type="text" placeholder="Search..." className="form-control" name="searchText" value={searchText} onChange={(e) => handleSearchChanged(e)} onKeyDown={(e) => handleInputSearchList(e)} />
                    <div className="input-group-append m-auto ">
                      <img style={{ cursor: "pointer" }} onClick={handleSearchList} src={SearchIcon} alt="iconmap" className="iconmap" />
                    </div>
                  </div>
                </Row>
              </Col>

              <Col xs={5} sm={5} md={5} lg={5} xl={5} xxl={5}>
                <Row>
                  <div className="d-flex justify-content-end">
                    {params.id === ":create" && (
                      <button className="commom_btn" style={{ width: "240px" }} data-bs-toggle="modal" data-bs-target="#exampleModal">Create Post</button>
                    )}
                    {params.id === ":updatelist" && (
                      <button className="commom_btn" style={{ width: "240px" }} onClick={() => navigate("post-update/create")}>Create Update</button>
                    )}
                    {params.id === ":eventlist" && (
                      <button className="commom_btn" style={{ width: "240px" }} onClick={() => navigate("post-event/create")} >Create Event</button>
                    )}
                    {params.id === ":speciallist" && (
                      <button className="commom_btn" style={{ width: "240px" }} onClick={() => navigate("post-special/create")}>Create Special</button>
                    )}
                  </div>
                </Row>
              </Col>

            </Row>
          </div>
          <Row className="post_data">
            <>
              <Row className="post_header">
                <Col xs={3} sm={3} md={4} lg={4} xl={4} xxl={4}>
                  <Row>
                    <Col className="align-self-center" xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}></Col>
                    <Col className="align-self-center p-0 text-start" xs={9} sm={9} md={9} lg={9} xl={9} xxl={9}>Post Name</Col>
                  </Row>
                </Col>
                {params.id !== ":eventlist" &&
                  <Col className="align-self-center text-center " xs={3} sm={3} md={6} lg={6} xl={6} xxl={6}>Type of Post</Col>
                }

                {params.id === ":eventlist" &&
                  <>
                    <Col className="align-self-center text-center " xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>Type of Post</Col>
                    <Col className="align-self-center text-center " xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>Ticket Link</Col>
                  </>
                }

                <Col className="text-end align-self-center" xs={3} sm={3} md={3} lg={3} xl={2} xxl={2} >
                  <Row>
                    <Col className="align-self-center text-center" xs={3} sm={3} md={4} lg={4} xl={12} xxl={12} >Status</Col>
                  </Row>
                </Col>
              </Row>

              {loading ? (
                initialPageNO ? (
                  <Loader />
                ) : (
                  <Loader />
                )
              ) : (
                allPostListData && allPostListData.map((key, index) => {
                  return (
                    <Row className="post_card">
                      <Col className="align-self-center" xs={3} sm={3} md={4} lg={4} xl={4} xxl={4} style={{ cursor: "pointer" }} onClick={() => handleUpdate(key.post_id, key.post_type)}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img style={{ width: "60px", height: "60px", borderRadius: "50%" }} src={key.image_url ? key.image_url : defaultImage} alt="" />
                          <div style={{ marginLeft: "25px" }}>
                            <div style={{ color: "#000000" }}>{key.post_Name}</div>
                            <div className="weeks ">{key.businessName}</div>
                          </div>
                        </div>
                      </Col>

                      {params.id !== ":eventlist" &&
                        <Col className="align-self-center text-center" xs={3} sm={3} md={6} lg={6} xl={6} xxl={6} style={{ cursor: "pointer" }} onClick={() => handleUpdate(key.post_id, key.post_type)}>
                          {key.post_type === "post-update" && (
                            <p style={{ margin: "0px" }}><img src={fadeupdate} style={{ marginRight: "5px" }} />Update</p>
                          )}
                          {key.post_type === "post-event" && (
                            <p style={{ margin: "0px" }}><img src={fadeevent} style={{ marginRight: "10px" }} />Event</p>
                          )}
                          {key.post_type === "post-special" && (
                            <p style={{ margin: "0px" }}><img src={fadespecial} style={{ marginRight: "5px" }} />Special</p>
                          )}
                        </Col>
                      }

                      {params.id === ":eventlist" &&
                        <>
                          <Col className="align-self-center text-center" xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} style={{ cursor: "pointer" }} onClick={() => handleUpdate(key.post_id, key.post_type)}>
                            {key.post_type === "post-update" && (
                              <p style={{ margin: "0px" }}><img src={fadeupdate} style={{ marginRight: "5px" }} />Update</p>
                            )}
                            {key.post_type === "post-event" && (
                              <p style={{ margin: "0px" }}><img src={fadeevent} style={{ marginRight: "10px" }} />Event</p>
                            )}
                            {key.post_type === "post-special" && (
                              <p style={{ margin: "0px" }}><img src={fadespecial} style={{ marginRight: "5px" }} />Special</p>
                            )}
                          </Col>

                          {key.ticketLink !== "" && key.ticketLink !== null ?
                            <Col className="align-self-center text-center" style={{ textOverflow: "ellipsis", overflow: "hidden" }} xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>

                              {/* <a href={`${key.ticketLink}`} target="_blank" style={{ color: "#979797", textDecoration: "none !important" }} >{key.ticketLink}</a> */}

                              <a href={`${key.ticketLink}`} target="_blank" style={{ color: "#979797", textDecoration: "none !important" }} >
                                <button className="commom_btn" style={{ width: "200px", padding: " 8px 0px 8px 0px", borderRadius: "5px" }}>purchase ticket</button>
                              </a>
                              {/* <img src={LinkIcon} height={12} width={24} style={{ marginLeft: "5px" }}></img> */}
                            </Col>
                            :
                            <Col className="align-self-center text-center " xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}></Col>
                          }
                        </>
                      }

                      <Col className="text-end align-self-center" xs={3} sm={3} md={2} lg={2} xl={2} xxl={2}>
                        <Row>
                          <Col className="align-self-center p-0 text-end" xs={3} sm={3} md={4} lg={4} xl={4} xxl={4}>
                            <Switch name="is_active" checked={key.status} onChange={(event, value) => { postStatusUpdate(key.post_id, value, key.post_type); }} />
                          </Col>

                          <Col className="align-self-center" xs={3} sm={3} md={4} lg={4} xl={4} xxl={4} onClick={() => handleUpdate(key.post_id, key.post_type)}>
                            <img src={editpen} style={{ cursor: "pointer" }} alt="" />
                          </Col>

                          <Col className="align-self-center" xs={3} sm={3} md={4} lg={4} xl={4} xxl={4} onClick={() => deleteConfirm(key.post_id, key.post_type, key.postName)} >
                            <img src={deleteicon} style={{ cursor: "pointer" }} alt="" />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  );
                })
              )}
            </>

            {!loadingPage ? (
              <div className="paginations">
                {count > 20 ?
                  <Stack spacing={2}>
                    <Pagination
                      firstPageText={
                        <i class="fa fa-angle-double-left" aria-hidden="true"></i>
                      }
                      prevPageText={
                        <i class="fa fa-angle-left" aria-hidden="true"></i>
                      }
                      lastPageText={
                        <i
                          class="fa fa-angle-double-right"
                          aria-hidden="true"
                        ></i>
                      }
                      nextPageText={
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                      }
                      itemsCountPerPage={20}
                      totalItemsCount={count}
                      activePage={activePage}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                      activeClass="active"
                      activeLinkClass="active"
                    />
                  </Stack>
                  : ""
                }
              </div>
            ) : (
              ""
            )}
          </Row>
        </div>

        {/* modal============= */}
        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header p-0 border-0">
                <button type="button" className="btn-close " data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                {post.map((post, index) => (
                  <div className={`d-flex post_type ${selectedItems && selectedItems[0].id == post.id ? "selected" : "unselect"}`} onClick={() => { selectItem(post, index); navigate(post.route) }}>
                    <div className={`icon_back ${post.icon} `}></div>
                    <p style={{ cursor: "pointer" }}>{post.name}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* modal============= */}
      </div>
    </>
  );
}
