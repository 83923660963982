export const getToken = () => {
    return sessionStorage.getItem("token") || null;
  };

  export const setUserSession = (token, user) => {
    sessionStorage.setItem("token", token);
    // let date = new Date();
    // cookies.set('token', token);
  };

  export const setUserEmailSession = (email) => {
    sessionStorage.setItem("email", email);
  };

  export const getUserEmail = () => {
    return sessionStorage.getItem("email") || null;
  };