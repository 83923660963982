import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Navbar from "../../components/common/navbar/Navbar";
import "./territories.scss";
import "../../styles/common.scss";
import Box from "@mui/material/Box";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FormControl, MenuItem, Select, Switch } from "@mui/material";
import deleteicon from "../../assets/images/delete.svg";
import terri from "../../assets/images/territories.svg";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useNavigate } from "react-router-dom";
import { deleteMethodWithId, getMethod, postMethod, putMethod } from "../../utils/apis/services/apis";
import url from "../../utils/apis/services/urls.json";
import { useEffect } from "react";
import ToastService from "react-material-toast";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const toast = ToastService.new({
  place: "topRight",
  duration: 2,
  maxCount: 4,
});

export default function Territories() {
  function useForceUpdate() {
    const [value, setValue] = useState(0);
    return () => setValue((value) => value + 1);
  }
  const forceUpdate = useForceUpdate();
  const navigate = useNavigate();
  const [countryId, setCountryId] = React.useState("");
  const [realCountry, setRealCountry] = React.useState([]);
  const [citiesId, setCitiesId] = React.useState("");
  const [stateId, setStateId] = React.useState("");
  const [territoriesValue, setTerritoriesValue] = React.useState("");
  const [expand, setExpand] = React.useState(false);
  const [modalVisible, setModalVisible] = useState(true);
  const [stateList, setStateList] = useState();
  const [cityList, setCityList] = useState();

  useEffect(() => {
    setModalVisible(true);
    countryDetailList();
    getTerritories();
  }, [modalVisible]);

  const countryDetailList = async () => {
    const result = await getMethod(url.getCountryList);

    setRealCountry(result && result.data);
  };
  const handleCountryChange = (event) => {
    setCountryId(event.target.value);
    handleStateList(event.target.value);
  };

  const handleStateList = async (id) => {
    const result = await getMethod(url.getCountryList + "?country_id=" + id);
    setStateList(result && result.data[0].data.state);
  };

  const handleStateChange = async (event) => {
    setStateId(event.target.value);
    const result = await getMethod(
      url.getCountryList +
      "?country_id=" +
      countryId +
      "&state_id=" +
      event.target.value
    );
    setCityList(result && result.data[0].data.cities);
  };

  const handleChangeCities = (event) => {
    setCitiesId(event.target.value);
  };

  const toggleAcordion = () => {
    setExpand((prev) => !prev);
  };

  const addTerritories = async () => {
    let body = {
      country_id: countryId,
      state_ids: [stateId],
      city_ids: [citiesId],
    };

    if (citiesId > 0 && stateId > 0 && countryId) {
      const result = await postMethod(url.addTerritory, body);

      if (result.status === 200 || result.status === 201) {
        const onClick = () => {
          toast.success(result.data.message, () => { });
        };
        onClick();

        setModalVisible(false);
      } else {
        if (citiesId === "") {
          alert("please enter valid detail");
        } else if (stateId === "") {
          alert("please enter valid detail");
        } else if (result.data.error) {
          const onErrorClick = () => {
            toast.error(result.data.error);
          };

          onErrorClick();
        }
      }
    } else {
      const onWarningClick = () => {
        toast.warning("please select All option");
      };
      onWarningClick();
    }
    setCitiesId("");
    setCountryId("");
    setStateId("");
    getTerritories();
  };

  const deleteStateConfirm = async (e, id, dataId, name) => {
    e.stopPropagation();
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to delete " + name + "?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteStateTerritories(id, dataId);
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  const deleteStateTerritories = async (id, dataId) => {
    const body = {
      state_id: id,
    };

    const onClick = () => {
      toast.success(result.data.message, () => { });
    };

    const onErrorClick = () => {
      toast.error(result.data.message);
    };

    const result = await deleteMethodWithId(url.deleteTerritory + dataId + "/", body);

    if (result.status === 200 || result.status === 201) {
      onClick(result.data.message);
    } else {
      onErrorClick(result.data.message);
    }

    getTerritories();
  };

  const deleteCityConfirm = async (id, dataId, name) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to delete " + name + "?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteTerritories(id, dataId);
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  const deleteTerritories = async (id, dataId) => {
    const body = {
      territory_city_ids: [id],
    };

    const onClick = () => {
      toast.success(result.data.message, () => { });
    };

    const onErrorClick = () => {
      toast.error(result.data.message);
    };

    const result = await deleteMethodWithId(url.deleteTerritory + dataId + "/", body);

    if (result.status === 200 || result.status === 201) {
      onClick(result.data.message);
    } else {
      onErrorClick(result.data.message);
    }

    getTerritories();
  };

  const updateTerritories = async (id, dataId) => {
    const body = {
      territory_city_ids: [id],
    };

    const onClick = () => {
      toast.success(result.data.message, () => { });
    };

    const onErrorClick = () => {
      toast.error(result.data.message);
    };

    const result = await putMethod(url.updateTerritory + dataId + "/", body);

    if (result.status === 200 || result.status === 201) {
      onClick(result.data.message);
    } else {
      onErrorClick(result.data.message);
    }

    getTerritories();
  };

  const updateStateTerritory = async (e, id, dataId, isDisabled) => {
    e.stopPropagation();

    const body = {
      state_id: id,
      status: isDisabled ? "markeActive" : "markeInactive",
    };

    const result = await putMethod(
      url.updateStateTerritories + dataId + "/status/update/",
      body
    );

    if (result.status === 200 || result.status === 201) {
      toast.success("State " + result.data.response + " successfully");
    } else {
      toast.error(result.data.response);
    }

    getTerritories();
  };

  const getTerritories = async () => {
    const result = await getMethod(url.addTerritory);
    if (result.status === 401) {
      toast.error(result.data.detail);
      navigate("/");
    }
    setTerritoriesValue(result && result.data);
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  /*const onDeletMethod = async (event, stateId, Id) => {
    event.stopPropagation();
    const body = {
      state_id: stateId,
    };
    const onClick = () => {
      toast.success(result.data.message, () => {});
    };
    const onErrorClick = () => {
      toast.error(result.data.message);
    };
    const result = await deleteMethod(url.deleteTerritory + Id + "/", body);
    if (result.status === 200 || result.status === 201) {
      onClick(result.data.message);
    } else {
      onErrorClick(result.data.message);
    }
    getTerritories();
  };*/

  const AccordionComponent = () => {
    return (
      <>
        <Accordion className="bar">
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" IconButtonProps={{ onClick: toggleAcordion }}>
            <div>
              <img className="flag" src={territoriesValue && territoriesValue.country.image_url} alt="" />
              <p className="d-inline-block">{territoriesValue && territoriesValue.country.name}</p>
            </div>
          </AccordionSummary>
          <AccordionDetails className="flex-column">
            {territoriesValue &&
              territoriesValue.country.state.map((item, index) => {
                return (
                  <Row className="accordion-city-data">
                    {item.cities.length > 0 && (
                      <Accordion className="bar">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          IconButtonProps={{
                            onClick: toggleAcordion,
                          }}
                        >
                          <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                            <div><p className="d-inline-block">{item.name}</p></div>
                            <div>
                              <Switch onClick={(event) => updateStateTerritory(event, item.id, territoriesValue.id, item.isDisabled)} checked={item.isDisabled ? false : true} />
                              <img src={deleteicon} style={{ marginLeft: "15px" }} alt="" onClick={(event) => deleteStateConfirm(event, item.id, territoriesValue.id, item.name)} />
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails className="flex-column">
                          {item.cities.map((items, index) => {
                            return (
                              <Row className="accordion-city-data">
                                <Col xs={7} sm={7} md={8} lg={9} xl={9} xxl={9} className="align-self-center city_text">{items.city.name}</Col>
                                <Col xs={7} sm={7} md={4} lg={3} xl={3} xxl={3}>
                                  <Row>
                                    <Col xs={7} sm={10} md={10} lg={10} xl={10} xxl={10} className="align-self-center d-flex justify-content-end" onClick={() => updateTerritories(items.id, territoriesValue.id)}>
                                      <Switch checked={items.is_disabled ? false : true} />
                                    </Col>
                                    <Col xs={7} sm={2} md={2} lg={2} xl={2} xxl={2} className="text-end align-self-center h-30" style={{ cursor: "pointer" }} >
                                      <img src={deleteicon} alt="" onClick={() => deleteCityConfirm(items.id, territoriesValue.id, items.city.name)} />
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            );
                          })}
                        </AccordionDetails>
                      </Accordion>
                    )}
                  </Row>
                );
              })}
          </AccordionDetails>
        </Accordion>
      </>
    );
  };

  return (
    <div className="territories">
      <Navbar backBtn={true} />
      <div className="content_area">
        <div className="content_block d-flex justify-content-between" style={{ display: "flex", alignItems: "center" }}>
          <div className="d-flex " style={{ display: "flex", alignItems: "center" }}>
            <div className="back_btn pe-4" style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="#000" Name="bi bi-arrow-left-short" viewBox="0 0 16 16" style={{ margin: "0px" }} >
                <path
                  fill-rule="evenodd"
                  d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
                />
              </svg>
            </div>
            <div className="" style={{ display: "flex", alignItems: "center" }}>
              <img src={terri} alt="" height={"30px"} />
              <p className="m-0" style={{ display: "flex", alignItems: "center" }}>Territories</p>
            </div>
          </div>
          <button className="commom_btn" data-bs-toggle="modal" data-bs-target="#exampleModal">Add Territory</button>
        </div>
        <Row className="operational_area"><p>Operational Area</p></Row>
        {AccordionComponent()}
      </div>

      {/* --------Modal-------- */}
      {modalVisible && (
        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="btn-close " data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className=" text-center d-inline-block">
                <h5 className="modal-title" id="exampleModalLabel">Add Territory</h5>
              </div>

              <div className="middel_body d-flex justify-content-center">
                <Box sx={{ width: 300 }} xl={{ minWidth: 300 }} className="me-5">
                  <FormControl sx={{ m: 1, minWidth: "100%" }} className="custom_dropdown">
                    <Select value={countryId} onChange={(value) => { handleCountryChange(value); }} displayEmptyinputProps={{ "aria-label": "Without label" }}>
                      <MenuItem value="" disabled><span>Country</span></MenuItem>
                      {realCountry && realCountry.map((data, index) => (
                        <MenuItem value={data.id}>{data.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={{ width: 300 }} xl={{ minWidth: 300 }} className="me-5">
                  <FormControl sx={{ m: 1, minWidth: "100%" }} className="custom_dropdown" >
                    <Select value={stateId} onChange={(value) => { handleStateChange(value); }} displayEmpty inputProps={{ "aria-label": "Without label" }}>
                      <MenuItem value="" disabled> <span>State</span></MenuItem>
                      {stateList &&
                        stateList.map((data, index) => (
                          <MenuItem value={data.id}>{data.name}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>

                <Box sx={{ width: 300 }}>
                  <FormControl sx={{ m: 1, minWidth: "100%" }} className="custom_dropdown">
                    <Select
                      value={citiesId}
                      onChange={(value) => {
                        handleChangeCities(value);
                      }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="" disabled><span>Cities</span></MenuItem>
                      {cityList && cityList.map((data, index) => (
                        <MenuItem value={data.id}>{data.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <div className="text-center d-inline-block">
                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => addTerritories()} > Save</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
