import React from 'react'
import { Audio, Hearts, Puff, Rings } from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export default function Loader(props) {
  return (
    <div className='d-flex align-items-center vh-auto justify-content-center' style={{marginTop:"200px"}}>
      <Puff className="d-flex "
        height="100"
        width="100"
        color='#19C1D6'
        ariaLabel='loading'
        visible={props.loading}
      />
    </div>
  )
}
