import React, { useState } from "react";
import { Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import DropZoneComponent from "../../components/common/dropzoneComponent/DropzoneComponent";
import Navbar from "../../components/common/navbar/Navbar";
import { postMethod, putMethod } from "../../utils/apis/services/apis";
import url from "../../utils/apis/services/urls.json";
import ToastService from "react-material-toast";
import { useParams } from "react-router";
import moment from "moment";

const toast = ToastService.new({
  place: "topRight",
  duration: 2,
  maxCount: 4,
});

export default function Event() {
  const params = useParams();
  const navigate = useNavigate();
  const [dataToPost, setDataToPost] = useState({});

  const getPostData = (data) => {
    setDataToPost(data);
  };

  const postEventData = async (value) => {
    const currentTime = new Date();

    if (params.id === "create") {
      let body = {
        postName: value.postName,
        business_id: value.bussinessID,
        location: value.location,
        description: value.description,
        country_id: value.countryID,
        state_id: value.stateID,
        city_id: value.cityID,
        tag_list: value.addTags,
        days_list: value.days_list,
        valid_from: value.valid_from,
        valid_to: value.valid_to,
        start_time: value.start_time,
        end_time: value.end_time,
        entryPrice: value.entryPrice,
        purchaseLink: value.purchaseLink
      };

      if (value.videoID.length !== 0) {
        body.video_ids = value.videoID;
      }

      if (value.imageID.length !== 0) {
        body.image_ids = value.imageID;
      }

      let result = '';
      if (value.valid_to === moment(currentTime).format("YYYY-MM-DD") && value.end_time < moment(currentTime).format("HH:mm:ss")) {
        toast.error("End time cannot be before current time");
      } else {
        result = await postMethod(url.createEventPosts, body);
      }

      if (result.status === 200) {
        toast.success(result.data.response);
        navigate("/post:eventlist");
      } else if (result.status === 400) {
        if (result.data.error.business_id && result.data.error.business_id[0]) {
          toast.error("business Name may not be blank");
        } else if (
          result.data.error.cities_id &&
          result.data.error.cities_id[0]
        ) {
          toast.error("Cities Name may not be blank");
        } else if (
          result.data.error.country_id &&
          result.data.error.country_id[0]
        ) {
          toast.error("Country Name may not be blank");
        } else if (
          result.data.error.purchaseLink &&
          result.data.error.purchaseLink[0]
        ) {
          toast.error("Ticket Link may not be blank");
        } else if (
          result.data.error.entryPrice &&
          result.data.error.entryPrice[0]
        ) {
          toast.error("Entry Price may not be blank");
        } else if (
          result.data.error.description &&
          result.data.error.description[0]
        ) {
          toast.error("Description may not be blank");
        } else if (
          result.data.error.location &&
          result.data.error.location[0]
        ) {
          toast.error("Location may not be blank");
        } else if (
          result.data.error.postName &&
          result.data.error.postName[0]
        ) {
          toast.error("Title may not be blank");
        } else if (
          result.data.error.state_id &&
          result.data.error.state_id[0]
        ) {
          toast.error("State Name not be blank");
        } else if (
          result.data.error.tag_list &&
          result.data.error.tag_list[0]
        ) {
          toast.error("Tags List may not be blank");
        } else if (
          result.data.error.end_time &&
          result.data.error.end_time[0]
        ) {
          toast.error("End Time may not be blank");
        } else if (
          result.data.error.start_time &&
          result.data.error.start_time[0]
        ) {
          toast.error("Start Time not be blank");
        } else if (
          result.data.error.valid_from &&
          result.data.error.valid_from[0]
        ) {
          toast.error("Start Date may not be blank");
        } else if (
          result.data.error.valid_to &&
          result.data.error.valid_to[0]
        ) {
          toast.error("ENd Date may not be blank");
        } else if (
          result.data.error.image_ids &&
          result.data.error.image_ids[0]
        ) {
          toast.error("Image Field may not be blank");
        }
      } else if (result && result.request) {
        toast.error(result.request.statusText);
      }
    } else {
      let body = {
        postName: value.postName,
        business_id: value.bussinessID,
        location: value.location,
        description: value.description,
        country_id: value.countryID,
        state_id: value.stateID,
        city_id: value.cityID,
        tag_list: value.addTags,
        days_list: value.days_list,
        valid_from: value.valid_from,
        valid_to: value.valid_to,
        start_time: value.start_time,
        end_time: value.end_time,
        image_ids: value.imageID,
        video_ids: value.videoID,
        entryPrice: value.entryPrice,
        purchaseLink: value.purchaseLink
      };

      let result = '';
      if (value.valid_to === moment(currentTime).format("YYYY-MM-DD") && value.end_time < moment(currentTime).format("HH:mm:ss")) {
        toast.error("End time cannot be before current time");
      } else {
        result = await putMethod(url.putPostEvent + params.id, body);
      }

      if (result.status === 200) {
        toast.success(result.data.response);
        navigate("/post:eventlist");
      } else if (result.status === 400) {
        if (result.data.error.business_id && result.data.error.business_id[0]) {
          toast.error("business Name may not be blank");
        } else if (
          result.data.error.cities_id &&
          result.data.error.cities_id[0]
        ) {
          toast.error("Cities Name may not be blank");
        } else if (
          result.data.error.country_id &&
          result.data.error.country_id[0]
        ) {
          toast.error("Country Name may not be blank");
        } else if (
          result.data.error.description &&
          result.data.error.description[0]
        ) {
          toast.error("Description may not be blank");
        } else if (
          result.data.error.purchaseLink &&
          result.data.error.purchaseLink[0]
        ) {
          toast.error("Ticket Link may not be blank");
        } else if (
          result.data.error.entryPrice &&
          result.data.error.entryPrice[0]
        ) {
          toast.error("Entry Price may not be blank");
        } else if (
          result.data.error.location &&
          result.data.error.location[0]
        ) {
          toast.error("Location may not be blank");
        } else if (
          result.data.error.postName &&
          result.data.error.postName[0]
        ) {
          toast.error("Title may not be blank");
        } else if (
          result.data.error.state_id &&
          result.data.error.state_id[0]
        ) {
          toast.error("State Name not be blank");
        } else if (
          result.data.error.tag_list &&
          result.data.error.tag_list[0]
        ) {
          toast.error("Tags List may not be blank");
        } else if (
          result.data.error.end_time &&
          result.data.error.end_time[0]
        ) {
          toast.error("End Time may not be blank");
        } else if (
          result.data.error.start_time &&
          result.data.error.start_time[0]
        ) {
          toast.error("Start Time not be blank");
        } else if (
          result.data.error.valid_from &&
          result.data.error.valid_from[0]
        ) {
          toast.error("Start Date may not be blank");
        } else if (
          result.data.error.valid_to &&
          result.data.error.valid_to[0]
        ) {
          toast.error("ENd Date may not be blank");
        }
      } else if (result && result.request) {
        toast.error(result.request.statusText);
      }
    }
  };

  return (
    <div className="event">
      <Navbar backBtn={true} />
      {/* <div className="Sidebar_control">
        <Sidebar />
      </div> */}
      <div className="content_area">
        <div className="content_block d-flex justify-content-between">
          <div className="d-flex">
            <div className="back_btn pe-4" style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="#000" Name="bi bi-arrow-left-short" viewBox="0 0 16 16" style={{ margin: "0px" }}>
                <path
                  fill-rule="evenodd"
                  d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
                />
              </svg>
            </div>
            <i className="bi bi-plus-circle d-flex align-self-center"></i>
            <p className="d-inline-block m-0">Create Event</p>
          </div>
          <button className="commom_btn" onClick={() => postEventData(dataToPost)} >{params.id === "create" ? "Create" : "Update"} </button>
        </div>
        <Row>
          <DropZoneComponent field={true} postingData={(data) => getPostData(data)} method={"post-event"} />
        </Row>
      </div>
    </div>
  );
}
