import React, { useEffect, useState } from "react";
import Navbar from "../../components/common/navbar/Navbar";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import Logo from "../../assets/images/defaultImage.png";
import Msg from "../../assets/images/mail.png";
import Call from "../../assets/images/call.png";
import "./updatebusiness.scss";
import { Col, Row, Toast } from "react-bootstrap";
import moment from "moment";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import url from "../../utils/apis/services/urls.json";
import { getMethod, postMethod, postMethodwithoutBody } from "../../utils/apis/services/apis";
import ToastService from "react-material-toast";

const toast = ToastService.new({
  place: "topRight",
  duration: 2,
  maxCount: 4,
});

function UpdateClaimedBusiness() {

  const navigate = useNavigate();
  const params = useParams();
  const [updateClaimedBusiness, setUpdateClaimedBusiness] = useState("");

  useEffect(() => {
    updateClaimedBusinessList();
  }, []);

  const updateClaimedBusinessList = async () => {
    const result = await getMethod(
      url.getClaimedBusinessDetails + params.id + "/"
    );
    if (result.status === 401) {
      toast.error(result.data.detail);
      navigate("/");
    }
    setUpdateClaimedBusiness(result.data);
  };

  const handlePostChange = async (val) => {
    const result = await postMethodwithoutBody(
      url.postClaimedBusiness + params.id + "/?action=" + val
    );
    if (result.status === 200) {
      toast.success(result.data.Success);
      val === "approve" && localStorage.setItem("activeTab", "approved");
      val === "onHold" && localStorage.setItem("activeTab", "onHold");
      navigate("/claimedBusiness");
    }
  };

  return (
    <>
      <div className="update-business">
        <Navbar />
        <div className="content_area">
          <div className="content_block d-flex justify-content-between">
            <div className="back_btn" onClick={() => navigate(-1)} style={{ cursor: "pointer", paddingLeft: "0px" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="#000" Name="bi bi-arrow-left-short" viewBox="0 0 16 16" style={{ margin: "0px" }}>
                <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
              </svg>
              <p className="d-inline-block m-0">Claimed Business</p>
            </div>

            {updateClaimedBusiness && updateClaimedBusiness.isApproved &&
              <p style={{ color: "#979797", fontSize: "20px", margin: "0px", display: "flex", alignItems: "center" }}>Approved</p>
            }
            {updateClaimedBusiness && updateClaimedBusiness.onHold &&
              <p style={{ color: "#979797", fontSize: "20px", margin: "0px", display: "flex", alignItems: "center" }}>On Hold</p>
            }
            {updateClaimedBusiness && !updateClaimedBusiness.isApproved && !updateClaimedBusiness.onHold &&
              <p style={{ color: "#979797", fontSize: "20px", margin: "0px", display: "flex", alignItems: "center" }}>In Review</p>
            }
          </div>

          <Row>
            <Col className="back_btn pe-4 d-flex" xs={7} sm={7} md={7} lg={7} xl={7} xxl={7} style={{ marginTop: "50px" }}>
            </Col>
            <Col className={`align-self-end`} xs={5} sm={5} md={5} lg={5} xl={5} xxl={5}>
              {updateClaimedBusiness &&
                updateClaimedBusiness.isApproved === false && (
                  <Tabs
                    variant="unstyled"
                    className={
                      updateClaimedBusiness.onHold ? "tabsStyle2 mb-2" : "tabsStyle1 mb-2"
                    }
                  >
                    <TabList>
                      <Tab
                        _selected={{ color: "white", background: "#19C1D6" }}
                        className="tabList"
                        onClick={() => handlePostChange("approve")}
                      >
                        Approved
                      </Tab>
                      {updateClaimedBusiness &&
                        updateClaimedBusiness.onHold === false && (
                          <Tab
                            _selected={{
                              color: "white",
                              background: "#19C1D6",
                            }}
                            className="tabList"
                            onClick={() => handlePostChange("onHold")}
                          >
                            OnHold
                          </Tab>
                        )}

                      <Tab
                        _selected={{ color: "white", background: "#19C1D6" }}
                        className="tabList"
                        onClick={() => handlePostChange("reject")}
                      >
                        Reject
                      </Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel></TabPanel>
                    </TabPanels>
                  </Tabs>
                )}
            </Col>
          </Row>

          <div className="claim-business">
            <Row>
              <Col className="align-self-center d-flex" xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{ cursor: "pointer" }}>
                <img src={updateClaimedBusiness && updateClaimedBusiness.user.length > 0 && updateClaimedBusiness.user[0].profile_image && updateClaimedBusiness.user[0].profile_image.filePath && updateClaimedBusiness.user.length > 0 && updateClaimedBusiness.user[0].profile_image !== null &&
                  updateClaimedBusiness.user[0].profile_image.filePath ? updateClaimedBusiness.user[0].profile_image.filePath : Logo} style={{ width: "60px", height: "60px", borderRadius: "50%", margin: "50px" }} />

                <Col className="align-self-start p-1 pt-2 text-start" xs={3} sm={3} md={9} lg={9} xl={9} xxl={9} style={{ cursor: "pointer" }}>
                  <h5 style={{ marginTop: "50px", marginLeft: "-30px" }}>
                    {updateClaimedBusiness &&
                      updateClaimedBusiness.user.length > 0 &&
                      updateClaimedBusiness.user[0].first_name}
                    {updateClaimedBusiness &&
                      updateClaimedBusiness.user.length > 0 &&
                      updateClaimedBusiness.user[0].last_name}
                  </h5>
                  <p style={{ marginTop: "-10px", marginLeft: "-30px" }}>Applied On :{moment(updateClaimedBusiness.approvedOn !== null && updateClaimedBusiness.approvedOn).format("DD MMMM YYYY")} </p>
                </Col>
              </Col>
              <Col className=" d-flex justify-content-center" xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{ marginTop: "60px" }}>
                <Row>
                  <Row>
                    <Col className=" d-flex " xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} >
                      <img src={Msg} style={{ width: "25px", height: "25px" }} />
                      <p style={{ marginLeft: "10px" }}> {updateClaimedBusiness && updateClaimedBusiness.user.length > 0 && updateClaimedBusiness.user[0].email} </p>
                    </Col>
                  </Row>
                  <Col className=" d-flex" xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <img src={Call} style={{ width: "25px", height: "25px" }} />
                    <p style={{ marginLeft: "10px" }}>{updateClaimedBusiness && updateClaimedBusiness.user.length > 0 && updateClaimedBusiness.user[0].country_code} {updateClaimedBusiness && updateClaimedBusiness.user.length > 0 && updateClaimedBusiness.user[0].phone}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col className=" justify-content-center" xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                <p>Business Name</p>
              </Col>
              <Col className="  justify-content-center" xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} >
                <p>Date of opening</p>
              </Col>
            </Row>
            <Row>
              <Col className=" justify-content-center" xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                <p style={{ color: "#bcbcbc" }}>{updateClaimedBusiness && updateClaimedBusiness.business.length > 0 && updateClaimedBusiness.business[0].businessName}</p>
              </Col>
              <Col className="  justify-content-center" xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                <p style={{ color: "#bcbcbc" }}>{moment(updateClaimedBusiness && updateClaimedBusiness.business[0].businessOpen_date !== null && updateClaimedBusiness.business[0].businessOpen_date).format("DD MMMM YYYY")} </p>
              </Col>
            </Row>
            <Row style={{ marginTop: "35px" }}>
              <Col className=" justify-content-center" xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                <p>Business Address </p>
              </Col>
            </Row>
            <Row>
              <Col className=" justify-content-center" xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                <p style={{ color: "#bcbcbc" }}> {updateClaimedBusiness && updateClaimedBusiness.business.length > 0 && updateClaimedBusiness.business[0].address} </p>
              </Col>
            </Row>
            <Row style={{ marginTop: "50px" }}>
              <p>Photo</p>
            </Row>
            <Row>
              {updateClaimedBusiness && updateClaimedBusiness.business.length > 0 && updateClaimedBusiness.business[0].image_ids.length > 0 && updateClaimedBusiness.business[0].image_ids.map((k, i) => (
                <Col className=" d-flex justify-content-center" xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                  <img src={k.file} className="updatePhoto" />
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateClaimedBusiness;
