import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./card.scss";
export default function Card(props) {
  const navigate = useNavigate();
  return (
    <>
      {props.cards.map((cards, index) => (
        <Col className="card-margin" xs={12} sm={12} md={6} lg={6} xl={6}>
          <div className="card" onClick={() => navigate(cards.id == "1" ? "./territories" : cards.id == "2" ? "./privacyPolicy" : cards.id == "3" ? "./termOfService" : cards.id == "4" ? "./additionalInformation" : "")}>
            <Row className="territories">
              <Col xs={12} sm={12} md={1} lg={1} xl={1}>
                <img src={cards.icon} alt="location" />
              </Col>
              <Col xs={12} sm={12} md={11} lg={11} xl={9} className="ms-4 d-flex align-items-center">
                <h3>{cards.heading}</h3>
              </Col>
            </Row>
            <p className="card_peragraph">{cards.peragraph}</p>
            <Row className="area">
              <Col xs={12} sm={12} md={1} lg={1} xl={2}>
                {cards.countryLogo ? (
                  <img src={cards.countryLogo} alt="country" />
                ) : (
                  ""
                )}
              </Col>
              <Col xs={12} sm={12} md={11} lg={11} xl={9} className="d-flex align-items-center">{cards.optional}</Col>
            </Row>
          </div>
        </Col>
      ))}
    </>
  );
}
