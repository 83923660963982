import React from 'react'
import { Row } from 'react-bootstrap'
import DropZoneComponent from '../../components/common/dropzoneComponent/DropzoneComponent'
import Navbar from '../../components/common/navbar/Navbar'
import Sidebar from '../../components/common/sidebar/Sidebar'

export default function Meetup() {
  return (
    <div className='meetup'>

      <Navbar backBtn={true} />

      <div className='content_area'>
        <div className="content_block d-flex justify-content-between">
          <div className="">
            <i className="bi bi-plus-circle"></i>
            <p className='d-inline-block'>Create Meetup</p>
          </div>
          <button className='commom_btn' data-bs-toggle="modal" data-bs-target="#exampleModal">Create</button>
        </div>
      </div>
    </div>
  )
}
