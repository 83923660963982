import React, { useEffect, useState } from 'react'
import { Row } from 'react-bootstrap'
import Navbar from '../../components/common/navbar/Navbar'
import privacy from '../../assets/images/privacy.svg'
import TextEditor from '../../components/common/textEditor/TextEditor'
import { useNavigate } from 'react-router';
import { getMethod, postMethod, putMethod } from '../../utils/apis/services/apis';
import url from "../../utils/apis/services/urls.json";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from '../loader/Loader'
import ToastService from "react-material-toast";
const toast = ToastService.new({
  place: "topRight",
  duration: 2,
  maxCount: 4
});

export default function PrivacyPolicy() {
  const navigate = useNavigate();
  const [htmlText, setHtmlText] = useState("");
  const [privacyPolicyList, setPrivacyPolicyList] = useState("");
  const [privacyPolicyId, setPrivacyPolicyId] = useState("");
  const [loading, setLoading] = useState(false)

  const handlePostData = async () => {
    let body = {
      "policy_text": htmlText
    }

    if (privacyPolicyId !== "") {
      let result = await putMethod(url.updatePrivacyPolicyData + privacyPolicyId + "/", body);
      if (result.status === 200) {
        toast.success(result.data)
        navigate(-1)
      }
    } else {
      let result = await postMethod(url.postPrivacyPolicyData, body);
      if (result.status === 201) {
        toast.success(result.data.message)
        navigate(-1)
      }
    }
  }

  // const handleGetData = async () => {
  //   setLoading(true)
  //   let result = await getMethod(url.getPrivacyPolicyData);
  //   if (result.status === 401) {
  //     toast.error(result.data.detail);
  //     navigate("/");
  //   }
  //   setPrivacyPolicyList(result.data);
  //   setLoading(false)
  // }

  // useEffect(() => {
  //   handleGetData();
  // }, [])

  const getEditorData = (value, id) => {
    setHtmlText(value);
    setPrivacyPolicyId(id)
  }
  return (
    <div className='privacyPolicy'>
      <Navbar backBtn={true} />
      <div className='content_area'>
        <div className="content_block d-flex justify-content-between" style={{ display: "flex", alignItems: "center" }}>
          <div className='d-flex' style={{ display: "flex", alignItems: "center" }}>
            <div className="back_btn pe-4" style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="#000" Name="bi bi-arrow-left-short" viewBox="0 0 16 16" style={{ margin: "0px" }}>
                <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
              </svg>
            </div>
            <div className="" style={{ display: "flex", alignItems: "center" }}>
              <img src={privacy} alt="" height={"30px"} />
              <p style={{ display: "flex", alignItems: "center", margin: "0px" }}>Privacy Policy</p>
            </div>
          </div>
          <button className='commom_btn' data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => handlePostData()}>Save</button>
        </div>
        <Row>
          <TextEditor getEditorData={(value, id) => getEditorData(value, id)} privacyPolicyList={privacyPolicyList} PrivacyPolicy={true} />
        </Row>
      </div>
    </div>
  )
}
