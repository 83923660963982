import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Navbar from "../../components/common/navbar/Navbar";
import defaultImage from "../../assets/images/defaultImage.png";
import deleteicon from "../../assets/images/delete.svg";
import editpen from "../../assets/images/editpen.svg";
import Switch from "@mui/material/Switch";
import associationType from "../../assets/images/campaign/skyAssociation.png";
import linkedType from "../../assets/images/campaign/skyLinked.png";
import "./Campaigns.scss";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { getMethod, deleteMethod, putMethod } from "../../utils/apis/services/apis";
import url from "../../utils/apis/services/urls.json";
import moment from "moment";
import ToastService from "react-material-toast";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "../../screens/loader/Loader";
import Pagination from "react-js-pagination";
import Stack from "@mui/material/Stack";
import "../../components/common/common.scss";

const post = [
  {
    id: 1,
    name: "Link based",
    icon: "fadeLinked",
    route: "/campaign/linkedbased/create/",
  },
  {
    id: 2,
    name: "Association based",
    icon: "fadeAssociation",
    route: "/campaign/associationbased/create/",
  },
];

const toast = ToastService.new({
  place: "topRight",
  duration: 2,
  maxCount: 4,
});

export default function Campaigns() {
  const dateFormat = "DD MMM YYYY";
  const navigate = useNavigate();
  const [selectedItems, setSelectedItems] = useState("");
  const [campaignsItems, setCampaignsItems] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [initialPageNO, setInitialPageNO] = useState(false);
  const [count, setCount] = useState(1);
  var [activePage, setPage] = useState(
    parseInt(
      localStorage.getItem("PageNo") ? localStorage.getItem("PageNo") : 1
    )
  );

  useEffect(() => {
    campaignsList();
  }, []);

  const campaignsList = async () => {
    setLoading(true);
    const result = await getMethod(
      url.getCampaignsData + "?page=" + activePage
    );
    if (result.status === 401) {
      toast.error(result.data.detail);
      navigate("/");
    }
    setCampaignsItems(result.data.results.data);

    setCount(result.data.count);
    setLoading(false);
  };

  const counts = Math.ceil(count / 20);

  const handlePageChange = async (event) => {
    setInitialPageNO(true);
    setLoadingPage(true);
    localStorage.setItem("PageNo", event);
    setPage(event);
    setLoading(true);
    const result = await getMethod(url.getCampaignsData + "?page=" + event);
    setCampaignsItems(result.data.results.data);
    setLoading(false);
    setLoadingPage(false);
  };

  const deleteConfirm = async (id, name) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to delete " + name + "?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteCampaign(id);
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  const deleteCampaign = async (id) => {
    const onClick = () => {
      const id = toast.success(result.data.response, () => { });
    };

    const result = await deleteMethod(url.deleteCampaign + id + "/");

    if (result.status == 200) {
      onClick(result.data.response);
    }

    campaignsList();
  };

  const campaignStatusUpdate = async (id, status) => {
    let body = {
      is_active: status,
    };

    const onClick = () => {
      const id = toast.success(result.data.response, () => { });
    };
    const result = await putMethod(url.putCampaignStatus + id + "/", body);

    if (result.status == 200) {
      onClick(result.data.response);
    }

    campaignsList();
  };

  const selectItem = (item, index) => {
    let items = [...selectedItems];
    setSelectedItems(items);
    if (items.includes(item)) {
      let index = items.indexOf(item);
      items.splice(index, 1);
    } else {
      items.pop(item);
      items.push(item);
    }
  };
  return (
    <>
      <div className="campaign">
        <Navbar backBtn={true} />
        {/* <div className="Sidebar_control">
          <Sidebar />
        </div> */}
        <div className="content_area">
          <div className="content_block d-flex justify-content-between">
            <div className="d-flex">
              <i className="bi bi-clock d-flex align-self-center"></i>
              <p className="d-inline-block m-0">Campaign</p>
            </div>
            <button className="commom_btn" style={{ width: "270px" }} data-bs-toggle="modal" data-bs-target="#exampleModal">Create Campaign</button>
          </div>

          <Row className="post_data">
            <>
              <Row className="post_header">
                <Col xs={3} sm={3} md={3} lg={3} xl={5} xxl={5}>
                  <Row>
                    <Col className="align-self-center" xs={3} sm={3} md={2} lg={2} xl={2} xxl={1}></Col>
                    <Col className="align-self-center p-0 text-start" xs={3} sm={3} md={9} lg={9} xl={9} xxl={9}>Campaign Name</Col>
                  </Row>
                </Col>
                <Col className="align-self-center text-center" xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>City / State</Col>
                <Col className="align-self-center text-center" xs={3} sm={3} md={3} lg={3} xl={2} xxl={2}>Type of Campaign</Col>
                <Col xs={3} sm={3} md={3} lg={3} xl={2} xxl={2} className="text-end align-self-center">
                  <Row>
                    <Col className="align-self-center text-center" xs={3} sm={3} md={4} lg={4} xl={12} xxl={12}>Status</Col>
                  </Row>
                </Col>
              </Row>

              {loading ? (
                initialPageNO ?
                  <Loader />
                  :
                  <Loader />
              ) : (
                campaignsItems &&
                campaignsItems.map((key, index) => {
                  return (
                    <Row className="post_card">
                      <Col xs={3} sm={3} md={3} lg={3} xl={5} xxl={5} style={{ cursor: "pointer" }} onClick={() => navigate(`/campaign/${key.promotion_type === "association-based-promotion" ? "associationbased" : "linkedbased"}/${key.id}`)}>
                        <Row>
                          <Col className="align-self-center" xs={3} sm={3} md={3} lg={3} xl={3} xxl={2}>
                            <img style={{ width: "60px", height: "60px", borderRadius: "50%" }} src={key.english_image_url ? key.english_image_url : defaultImage} alt="" />
                          </Col>
                          <Col className="align-self-start p-0 text-start" xs={3} sm={3} md={9} lg={9} xl={9} xxl={9} >
                            {key.name}
                            <div className="weeks d-flex" style={{ margin: "5px 0px" }}>
                              {key.weekday.sort((a, b) => a.id - b.id).map((key, index) => {
                                return (
                                  <div className="days">
                                    {key.name.substring(0, 2)}
                                  </div>
                                );
                              })}
                            </div>
                            {moment(key.start_date).format(dateFormat)} to{" "}
                            {moment(key.end_date).format(dateFormat)}
                          </Col>
                        </Row>
                      </Col>
                      <Col className="align-self-center text-center" xs={3}
                        sm={3} md={3} lg={3} xl={3} xxl={3} style={{ cursor: "pointer" }} onClick={() => navigate(`/campaign/${key.promotion_type === "association-based-promotion" ? "associationbased" : "linkedbased"}/${key.id}`)}>
                        {key.city.name}, {key.state.name}
                      </Col>
                      <Col className="align-self-center text-center" xs={3} sm={3} md={3} lg={3} xl={2} xxl={2} style={{ cursor: "pointer" }} onClick={() => navigate(`/campaign/${key.promotion_type === "association-based-promotion" ? "associationbased" : "linkedbased"}/${key.id}`)}>
                        <img style={{ width: "38px" }} src={key.promotion_type === "association-based-promotion" ? associationType : linkedType} />
                      </Col>
                      <Col xs={3} sm={3} md={3} lg={3} xl={2} xxl={2} className="text-end align-self-center">
                        <Row>
                          <Col className="align-self-center p-0 text-end" xs={3} sm={3} md={5} lg={5} xl={4} xxl={4}>
                            <Switch
                              name="is_active"
                              checked={key.is_active}
                              onChange={(event, values) => {
                                campaignStatusUpdate(key.id, values);
                              }}
                            />
                          </Col>
                          <Col className="align-self-center" xs={3} sm={3} md={5} lg={8} xl={4} xxl={4}>
                            <Link to={`/campaign/${key.promotion_type === "association-based-promotion" ? "associationbased" : "linkedbased"}/${key.id}`}>
                              <img src={editpen} style={{ cursor: "pointer" }} alt="" />
                            </Link>
                          </Col>
                          <Col className="align-self-center" xs={3} sm={3} md={5} lg={2} xl={4} xxl={4} onClick={() => deleteConfirm(key.id, key.name)}>
                            <img src={deleteicon} style={{ cursor: "pointer" }} alt="" />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  );
                })
              )}
            </>

            {!loadingPage ? (
              <div className="paginations">
                {count > 20 ?
                  <Stack spacing={2}>
                    <Pagination
                      firstPageText={
                        <i class="fa fa-angle-double-left" aria-hidden="true"></i>
                      }
                      prevPageText={
                        <i class="fa fa-angle-left" aria-hidden="true"></i>
                      }
                      lastPageText={
                        <i
                          class="fa fa-angle-double-right"
                          aria-hidden="true"
                        ></i>
                      }
                      nextPageText={
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                      }
                      itemsCountPerPage={20}
                      totalItemsCount={count}
                      activePage={activePage}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                      activeClass="active"
                      activeLinkClass="active"
                    />
                  </Stack>
                  : ""
                }
              </div>
            ) : (
              ""
            )}
          </Row>
        </div>

        {/* modal============= */}
        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header p-0 border-0">
                <button type="button" className="btn-close " data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                {post.map((post, index) => (
                  <div className={`d-flex post_type ${selectedItems && selectedItems[0].id == post.id ? "selected" : "unselect"}`} onClick={() => { selectItem(post, index); navigate(post.route) }}>
                    <div className={`icon_back ${post.icon} `}></div>
                    <p style={{ cursor: "pointer" }}>{post.name}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* modal============= */}
      </div>
    </>
  );
}
