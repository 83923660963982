import React, { useState } from "react";
import { TextField } from "@mui/material";
import { Form, Formik } from "formik";
import { Button, Col, Image, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import companyLogoDark from "../../assets/images/companyLogoDark.svg";
import berbLogoDark from "../../assets/images/berb-logo-black.svg";
import "./OtpVerification.scss";
import ToastService from "react-material-toast";
import {
  postMethod,
  postWithoutTokenMethod,
} from "../../utils/apis/services/apis";
import url from "../../utils/apis/services/urls.json";
import OtpInput from "react-otp-input";
import { getUserEmail, setUserSession } from "../../utils/apis/services/common";

const toast = ToastService.new({
  place: "topRight",
  duration: 2,
  maxCount: 3,
});

export default function OtpVerification() {
  const navigate = useNavigate();

  const [state, setState] = useState("");

  const handleChange = (otp) => setState({ otp });

  const onErrorClick = () => {
    const id = toast.error("please fill all the field correctly");
  };

  const handleSubmit = async () => {
    let body = {
      "username": getUserEmail(),
      "otp": state.otp
    };
    const result = await postWithoutTokenMethod(url.otpVerification, body);
    if (result.status === 200) {
      toast.success(result.data.success)
      setUserSession(result.data.token);
      navigate('/businesses');
    } else if (result.status === 400) {
      toast.error(result.data.error)
    } else (
      toast.error("Invalid OTP")
    )

  };
  return (
    <div className="bg-white vh-100 forgetPassword">
      <div className="">
        <Image className="mt-5 ms-5" src={berbLogoDark} alt="Berb" width="180px" />
      </div>
      <Row className="text-center content-block">
        <Row className="justify-content-center local-text">OTP VERIFICATION</Row>
        <Row className="justify-content-center Fp_description mb-4">
          <p>Enter Email associated with your account. We will send an Otp with instructions to verify your Email.</p>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center">
            <OtpInput
              value={state.otp}
              onChange={handleChange}
              numInputs={6}
              inputStyle={{
                width: "68px",
                height: "50px",
                margin: "10px",
                fontSize: "25px",
              }}
            />
          </Col>
        </Row>

        <Row className="justify-content-center mt-5">
          <Button className="continue_button" onClick={() => handleSubmit()}>Otp Verify</Button>
        </Row>
      </Row>
    </div>
  );
}
