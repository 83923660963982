import logo from './logo.svg';
import './App.scss';
import Route from './routes/Route';

function App() {
    return (
        <>
            <Route/>
        </>
    );    
}
export default App;
