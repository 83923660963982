import { Switch } from "@mui/material";
import React, { useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Navbar from "../../components/common/navbar/Navbar";
import deleteicon from "../../assets/images/delete.svg";
import editpen from "../../assets/images/editpen.svg";
import foodicon from "../../assets/images/foodicon.png";
import defaultImage from "../../assets/images/defaultImage.png";
import "./businesses.scss";
import "../../styles/common.scss";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { deleteMethod, getMethod, putMethod, postUploadMethod, } from "../../utils/apis/services/apis";
import url from "../../utils/apis/services/urls.json";
import { useState } from "react";
import ToastService from "react-material-toast";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "../../screens/loader/Loader";
import Pagination from "react-js-pagination";
import Stack from "@mui/material/Stack";
import "../../components/common/common.scss"
import SearchIcon from "../../assets/images/10-icon.svg";
import Lottie from 'react-lottie';
import animationData from '../../assets/lottie/businessSearchEmpty';
import Modal from "react-modal"
import CloseIcon from '@mui/icons-material/Close';

const toast = ToastService.new({
  place: "topRight",
  duration: 2,
  maxCount: 4,
});

export default function Businesses() {
  const navigate = useNavigate();
  const [screenSize, getDimension] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [business, setBusiness] = useState([]);
  const [filePath, setFilePath] = useState("Upload Excel");
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [initialPageNO, setInitialPageNO] = useState(false);
  const [uploadModal, setUploadModal] = useState(false)
  const [count, setCount] = useState(1);
  var [activePage, setPage] = useState(
    parseInt(
      localStorage.getItem("PageNo") ? localStorage.getItem("PageNo") : 1
    )
  );
  const [formValue, setFormValue] = useState({
    file: "",
  });

  const customStyles = {
    content: {
      top: "39%",
      left: "60%",
      transform: screenSize.width <= 800 ? 'translate(-20%, -50%)' : 'translate(-42%, -50%)',
      backgroundColor: "white",
      border: "1px solid black",
      borderRadius: "4px",
      overflow: "auto",
      WebkitOverflowScrolling: "touch",
      outline: "none",
      padding: "15px",
      width: screenSize.width <= 800 ? "40vw" : screenSize.width <= 1100 ? "28vw" : "34vw",
      height: screenSize.width <= 800 ? "25vh" : "25vh",
    },
  };

  useEffect(() => {
    businessList();
  }, []);
  const businessList = async () => {
    setLoading(true);
    const result = await getMethod(url.getBusinesses + "?page=" + activePage);
    if (result.status === 401) {
      toast.error(result.data.detail);
      navigate("/");
    }
    setBusiness(result.data.results.data);
    setCount(result.data.count);
    setLoading(false);
  };

  const counts = Math.ceil(count / 20);

  const handlePageChange = async (event) => {
    setInitialPageNO(true)
    setLoading(true);
    setLoadingPage(true);
    localStorage.setItem("PageNo", event);
    setPage(event);
    if (searchText) {
      const result = await getMethod(url.getBusinesses + "?page=" + event + "&searchtext=" + searchText);
      setBusiness(result.data.results.data);
    } else {
      const result = await getMethod(url.getBusinesses + "?page=" + event);
      setBusiness(result.data.results.data);
    }
    setLoading(false);
    setLoadingPage(false);
  };

  const handleSearchChanged = async (event) => {
    setSearchText(event.target.value)
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const handleInputSearchList = async (event) => {
    if (event.keyCode === 13) {
      setInitialPageNO(true)
      setLoading(true);
      setLoadingPage(true);
      localStorage.setItem("PageNo", 1);
      setPage(1);
      const result = await getMethod(url.getBusinesses + "?page=1" + "&searchtext=" + searchText);
      setBusiness(result.data.results.data);
      setCount(result.data.count);
      setLoading(false);
      setLoadingPage(false);
    }
  };

  const handleSearchList = async () => {
    setInitialPageNO(true)
    setLoading(true);
    setLoadingPage(true);
    localStorage.setItem("PageNo", 1);
    setPage(1);
    const result = await getMethod(url.getBusinesses + "?page=1" + "&searchtext=" + searchText);
    setBusiness(result.data.results.data);
    setCount(result.data.count);
    setLoading(false);
    setLoadingPage(false);
  }

  const deleteConfirm = async (id, name) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to delete " + name + "?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteBusiness(id);
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };
  const deleteBusiness = async (id) => {
    const onClick = () => {
      const id = toast.success(result.data.response, () => { });
    };

    const onErrorClick = () => {
      const id = toast.error(result.data.response, () => { });
    };

    const result = await deleteMethod(url.deleteBusiness + id + "/");

    if (result.status == 200 || result.status == 201) {
      onClick(result.data.response);
    } else {
      onErrorClick(result.data.response);
    }

    businessList();
  };
  const postBusinessStatusUpdate = async (id, status) => {
    let body = {
      isActive: status,
    };

    const onClick = () => {
      const id = toast.success(result.data.response, () => { });
    };

    const onErrorClick = () => {
      const id = toast.error(result.data.response, () => { });
    };

    const result = await putMethod(url.putBusinessStatus + id + "/", body);

    if (result.status == 200 || result.status == 201) {
      onClick(result.data.response);
    } else {
      onErrorClick(result.data.response);
    }

    businessList();
  };

  const openUploadModal = () => {
    setUploadModal(true)
  }

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.files[0] });

    let formfilevalue = e.target.value;
    setFilePath(formfilevalue.replace(/.*(\/|\\)/, ""));
  };

  const handleSubmit = async () => {

    const onClick = () => {
      const id = toast.success(result.data.response, () => { });
    };



    let result = await postUploadMethod(url.postBusinessUpload, formValue);

    if (result.status == 200 || result.status == 201) {
      onClick(result.data.response);
      setUploadModal(false)
      businessList()
      setFormValue()
      setFilePath("Upload Excel");
    } else {
      toast.error("Only Excel file is accepted")
    }
    setFilePath("Upload Excel");
  };

  return (
    <>
      <div className="businesses">
        <Navbar backBtn={false} />
        <div className="content_area">
          <div className="content_block">
            <Row>
              <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                <p className="d-inline-block">Businesses</p>
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                <Row className="input_area business-search">
                  <div className="input-group mb-3 p-0 ">
                    <input type="text" placeholder="Search..." className="form-control" name="searchText" value={searchText} onChange={(e) => handleSearchChanged(e)} onKeyDown={(e) => handleInputSearchList(e)} />
                    <div className="input-group-append m-auto ">
                      <img style={{ cursor: "pointer" }} onClick={handleSearchList} src={SearchIcon} alt="iconmap" className="iconmap" />
                    </div>
                  </div>
                </Row>
              </Col>

              <Col xs={5} sm={5} md={5} lg={5} xl={5} xxl={5}>
                <Row>
                  <div className="d-flex justify-content-end">
                    <Button className="commom_btn me-3" onClick={() => navigate("./createBusiness/" + "add")}>Add</Button>

                    {/* <Button
                      className="commom_btns"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      Upload Excel
                    </Button> */}
                    <Button onClick={() => openUploadModal()} className="commom_btns" >Upload Excel</Button>
                  </div>
                </Row>
              </Col>
            </Row>
          </div>
          <>
            <Row className="post_header">
              <Col xs={3} sm={3} md={3} lg={3} xl={5} xxl={5}>
                <Row>
                  <Col className="align-self-center" xs={3} sm={3} md={2} lg={2} xl={2} xxl={2} />
                  <Col className="align-self-center p-0" xs={3} sm={3} md={9} lg={9} xl={9} xxl={9}>Business Name</Col>
                </Row>
              </Col>
              <Col className="align-self-center text-center" xs={3} sm={3} md={3} lg={3} xl={2} xxl={2}>Address</Col>
              <Col className="align-self-center text-center" xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>Type of Business</Col>
              <Col xs={3} sm={3} md={3} lg={3} xl={2} xxl={2}>
                <Row>
                  <Col className="align-self-center text-center" xs={3} sm={3} md={4} lg={4} xl={12} xxl={12}>Status</Col>
                </Row>
              </Col>
            </Row>
            {loading ? (
              initialPageNO ?
                <Loader />
                :
                <Loader />
            ) : (
              business.length > 0 ? business.map((data) => {
                return (
                  <Row className="post_card">
                    <Col xs={3} sm={3} md={3} lg={3} xl={5} xxl={5} style={{ cursor: "pointer" }} onClick={() => navigate("./createBusiness/" + data.id)}>
                      <Row>
                        <Col className="align-self-center" xs={3} sm={3} md={3} lg={3} xl={3} xxl={2}>
                          <img style={{ width: "60px", height: "60px", borderRadius: "50%" }} src={data.image_ids ? data.image_ids : defaultImage} alt="{data.businessName}" />
                        </Col>
                        <Col className="align-self-center p-0 businessName" xs={3} sm={3} md={9} lg={9} xl={9} xxl={9}>{data.businessName}</Col>
                      </Row>
                    </Col>
                    <Col className="align-self-center text-center" xs={3} sm={3} md={3} lg={3} xl={2} xxl={2} style={{ cursor: "pointer" }} onClick={() => navigate("./createBusiness/" + data.id)}>
                      {data.address}
                    </Col>
                    <Col className="align-self-center text-center" xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} style={{ cursor: "pointer" }} onClick={() => navigate("./createBusiness/" + data.id)}>
                      {data.businessType.map((type) => {
                        return <img style={{ width: "40px", marginRight: "5px" }} src={type.icon ? type.icon : foodicon} alt="{type.name}" />
                      })}
                    </Col>
                    <Col xs={3} sm={3} md={3} lg={3} xl={2} xxl={2} className="text-end align-self-center">
                      <Row>
                        <Col className="align-self-center p-0 text-end" xs={3} sm={3} md={5} lg={5} xl={4} xxl={4}  >
                          <Switch name="isActive" checked={data.isActive} onChange={(event, value) => { postBusinessStatusUpdate(data.id, value); }} />
                        </Col>
                        <Col className="align-self-center" xs={3} sm={3} md={5} lg={8} xl={4} xxl={4}>
                          <Link to={`./createBusiness/${data.id}`}><img src={editpen} alt="" /></Link>
                        </Col>
                        <Col className="align-self-center" xs={3} sm={3} md={5} lg={2} xl={4} xxl={4} onClick={() => deleteConfirm(data.id, data.businessName)} style={{ cursor: "pointer" }} >
                          <img src={deleteicon} alt="" />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                );
              })
                :
                <div>
                  <Lottie options={defaultOptions} height={500} width={500} />
                </div>
            )}
          </>
          {!loadingPage ? (
            <div className="paginations">
              {count > 20 ?
                <Stack spacing={2}>
                  <Pagination
                    firstPageText={
                      <i class="fa fa-angle-double-left" aria-hidden="true"></i>
                    }
                    prevPageText={
                      <i class="fa fa-angle-left" aria-hidden="true"></i>
                    }
                    lastPageText={
                      <i
                        class="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                    }
                    nextPageText={
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                    }
                    itemsCountPerPage={20}
                    totalItemsCount={count}
                    activePage={activePage}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    activeClass="active"
                    activeLinkClass="active"

                  />
                </Stack>
                : ""}
            </div>
          ) : (
            ""
          )}
        </div>
        {/* modal----------csv */}
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header border-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <Row>
                  <div class="csv-file-upload-wrapper" data-text={filePath}>
                    <input name="file" type="file" accept={".xlsx"} class="csv-file-upload-field" placeholder="Upload Excel" onChange={(e) => handleChange(e)} />
                  </div>
                </Row>
                <Row className="text-center d-block mt-4">
                  <Button className="commonBtn" data-bs-dismiss="modal" onClick={() => handleSubmit()}>Upload Excel</Button>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <Modal isOpen={uploadModal} style={customStyles} closeTimeoutMS={500}>
          <div className="mb-4 modal-upload" tabindex="-1">
            <div className="d-flex justify-content-end" >
              <div style={{ cursor: "pointer" }}>
                <CloseIcon onClick={() => setUploadModal(false)} />
              </div>
            </div>
            <div className=" col-12 pt-3">
              <div className={`input-group customerFileBtn`}>
                <label className="input-group-text" for="inputGroupFile">Upload XLSX</label>
                <input name="file" type="file" accept={".xlsx"} className="form-control" id="inputGroupFile" onChange={(e) => handleChange(e)} />
              </div>
            </div>

            <div className="col-12 mt-4 d-flex justify-content-center">
              <Button onClick={() => handleSubmit()} className="upload-btn">Upload Excel</Button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}
